import { FontFamilySwitch } from "../../utils/font";

export const tabStyle = {
    tab: {
        marginLeft: {
            sm: "25px",
            md: "25px",
        },
        fontFamily: FontFamilySwitch().medium
        }
}