import * as React from 'react';

export const Pin = ({ number = '', nearBy = false }) => {
    return (
        <svg width="51.251" height="60.987" viewBox="0 0 51.251 60.987">
            <defs>
                <filter id="Union_3" x="0" y="0" width="51.251" height="60.987" filterUnits="userSpaceOnUse">
                    <feOffset dy="4" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g id="Component_89_10" data-name="Component 89 – 10" transform="translate(9 5)">
                <g transform="matrix(1, 0, 0, 1, -9, -5)" filter="url(#Union_3)">
                    <path id="Union_3-2" data-name="Union 3" d="M16.625,0A16.648,16.648,0,0,0,0,16.625c0,3.818,2.308,8.387,5.035,12.933a129.476,129.476,0,0,0,8.106,11.734,4.43,4.43,0,0,0,6.968,0,129.388,129.388,0,0,0,8.106-11.734c2.727-4.547,5.035-9.116,5.035-12.933A16.648,16.648,0,0,0,16.625,0Z" transform="translate(9 5)" fill={nearBy ? "#1F74EA" : "#091b29"} />
                </g>
                <text id="_202" data-name="202" transform="translate(16.166 20.385)" fill="#fff" font-size="12" font-family="system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'NotoSans-ExtraBold'" font-weight="700"><tspan x="-2" y="0">{number}</tspan></text>
            </g>
        </svg>
    );
}