import { borderRadius, borderRadiusAlignments, remCalc } from "../../utils";
import { FontFamilySwitch } from "../../utils/font";

export const AvailableCardStyle = {
  mainBox: {
    display: "flex",
    height: "88px",
    backgroundColor: "primary.contrastText",
    border: "1px solid #CED3DD",
    borderRadius: borderRadiusAlignments?.borderEight,
    alignItems: "center",
    // boxShadow:"0px 0px 32px #0000000f",
    cursor: "pointer",
    justifyContent: "space-between",
    // padding:"0px 8px 0px 0px",
    padding: "12px 16px 16px 16px",
    "&:hover": {
      boxShadow: 2,
      "& .arrowhover": {
        color: "#1F74EA",
      },
    },
  },
  content: {
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "column",
    // flexGrow: 1
  },
  mainimage: {
    display: "flex",
    alignItems: "center",
    padding: "12px",
  },
  image: {
    height: "60px",
    // width: "64px",
    width: "50px",
    objectFit: 'contain',
    borderRadius: borderRadius
  },
  text: {
    // padding: "4px 8px 4px 4px ",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"

  },
  text1: {
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: remCalc(14),
    color: "text.primary",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  text2: {
    fontSize: remCalc(12),
    fontFamily: FontFamilySwitch().medium,
    color: "text.success",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  arrowicon: {
    color: "text.tertiary",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  textheader: {
    display: "flex",
    justifyContent: "center"
  },
  amenietyMaster: {
    fontSize: remCalc(12),
    fontFamily: FontFamilySwitch().medium,
    color: "text.tertiary",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    // width:"90%"
  },
  firstBox: {
    display: "flex",
    alignItems: "center",

  },
  label:{
    marginInlineStart:'12px'
  }
};
