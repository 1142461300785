import { FontFamilySwitch } from "../../utils/font";

export const RadioButtonStyle = {
  iconbutton: {
    mt: "8px",
    ml:"10px",
    "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium": {
      height: "20px",
      width: "20px"
    },

    "& .MuiTypography-root": {
      color: "#091B29",
      fontSize:  "0.875rem",
      fontFamily: FontFamilySwitch().semiBold
    }
  }
};
