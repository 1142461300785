import { borderRadius } from "../../utils";
import { FontFamilySwitch } from "../../utils/font";

export const TextFeildStyle = {
  root: {
    width: '100%'
  },
  Label: {
    color: 'primary.main',
    fontSize: (props) => props?.labelSize ?? '1.2rem',
    marginBottom: (props) => props?.labelMarginBottom ?? "5px",
  },
  text: {},
  required: {
    color: "red",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: (props) => props?.labelSize ?? '1.2rem',
    marginBottom: (props) => props?.labelMarginBottom ?? "5px",
  },
  textbox: {
    backgroundColor: (props) => props?.color ?? "auto",
    borderRadius: borderRadius,
    border: "none",
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
      backgroundColor: '#fff'
    },
    [`& fieldset`]: {
      borderRadius: borderRadius,
      height: (props) => (props.multiline ? "unset" : props?.height ?? 50),
      border: (props) => props?.border ?? "1.5px solid #fff",
      "&:hover": {
        border: "1px solid red",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: (props) => props?.padding ?? "11px 14px",
      borderRadius: borderRadius,
      backgroundColor: "#fff"
    },
    "& .MuiOutlinedInput-input:focused": {
      padding: (props) => props?.padding ?? "11px 14px",
      backgroundColor: "#fff"
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#fff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#fff',
      },
    },
  },
  searchTexbox: {
    borderRadius: '8px',
    height: '58px',
    backgroundColor: (props) => props?.color ?? "auto",
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
      backgroundColor: '#fff'
    },
    [`& fieldset`]: {
    },
    "& .MuiOutlinedInput-input": {
      backgroundColor: "#fff",
      paddingInlineStart: 1,
    },
    "& .MuiOutlinedInput-input:focused": {
      backgroundColor: "#fff"
    },
    '& .MuiOutlinedInput-root': {
      height: '58px',
      borderRadius: '8px',
      backgroundColor: "#fff",
      '&:hover fieldset': {
        borderColor: 'border.default'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'primary.main',
      },
    },
    "& .MuiInputBase-input": {
      padding: "8px 8px"
    }
  },
}  