import React from "react";
import { Themes, AlertProps } from "../utils";

/**
 * ThemeContext store the current theme of the app,which is provided
 * at the /src/App.js using the /src/App.theme.js.
 */
export let ThemeContext = React.createContext({
  name: Themes.default,
  setTheme: () => null,
});

export let AlertContext = React.createContext({
  open: false,
  severity: AlertProps.severity.success ?? AlertProps.severity.success,
  msg: "",
  vertical: AlertProps.vertical.top,
  horizontal: AlertProps.horizontal.center,
  onclose: () => null,
  setSnack: () => null,
  style: false,
  submsg: "",
});

/* 
Firebase context is for getting a token,deleting a token and requesting permission.
**/
export let FirebaseContext = React.createContext({
  token: "",
  isTokenFound: false,
  getToken: () => false,
  requestPermission: () => false,
  deleteToken: () => false,
});

export let AuthContext = React.createContext({
  auth: {},
  setAuth: () => null,
  updateAuth: () => false,
});
