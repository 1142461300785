import { FontFamilySwitch } from "../../utils/font";

export const LocationSearchStyle = {
    search: {
        "& div": {

            "& div": {
                // border: "1px solid",
                borderColor: "border.primary",
                height: 48,
                borderRadius: 8,
                "& input": {
                    padding: " 4px 4px 7.5px 6px !important"
                },
            }
        }
    },
    listSearch: {
        "& div": {
            "& div": {
                // border: "1px solid",
                borderColor: "border.primary",
                height: 48,
                borderRadius: "12px",
            }
        }
    },
    menu: {
        height: 400,
        width: 400,
        boxShadow: "0px 2px 12px #00000014",
        background: "#fff",
        position: "absolute",
        zIndex: 1,
        marginTop: "5px"
    },
    text: {
        fontFamily: FontFamilySwitch().semiBold,
        fontSize:  "0.875rem",
        color: "text.tertiary"
    },
    localSearchBox:{
        width: "100%", 
        position: "relative"
    }
}