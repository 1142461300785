import { borderRadius, borderRadiusAlignments } from "../../../utils";
import { FontFamilySwitch } from "../../../utils/font";

export const bookingCardStyles = {
    mainBox: {
        // height: "111px",
        border: "1px solid #E4E8EE",
        borderRadius: borderRadius,
        backgroundColor: "text.default",
        cursor: "pointer",
        padding: "16px",
        boxShadow: "#0000000F 0px 0px 32px",
    },
    image: {
        height: "48px",
        width: "48px",
        borderRadius: borderRadiusAlignments?.borderEight,

    },
    imgBox: {
        // margin: "16px",
        display: "flex"
    },
    textBox: {
        display: "Flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center"
    },
    text: {
        fontFamily: FontFamilySwitch().medium,
        fontSize: "1rem",
        color: "text.primary",

    },

    chipbooked: {
        backgroundColor: "#5AC782",
        borderRadius: borderRadius,
        fontFamily: FontFamilySwitch().medium,
        fontSize: "0.75rem",
        color: "text.default",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        padding: "3px 6px"
    },
    chipcancel: {
        backgroundColor: "#FF4B4B",
        borderRadius: borderRadius,
        fontFamily: FontFamilySwitch().medium,
        fontSize: "0.75rem",
        color: "text.default",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        padding: "3px 6px"
    },
    chipcontacted: {
        backgroundColor: "#78B1FE",
        borderRadius: borderRadius,
        fontFamily: FontFamilySwitch().medium,
        fontSize: "0.75rem",
        color: "text.default",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        padding: "3px 6px"
    },

    text2: {
        // ml: "12px",
        mt: "5px",
        fontFamily: FontFamilySwitch().regular,
        fontSize: "0.875rem",
        color: "#4E5A6B"
    },
    content: {
        width: "100%",
        // mr: "12px"
    },
    details: {
        display: "flex",
        // margin: "16px",
        alignItems: "center",
        mt: "12px"

    },
    time: {
        fontFamily: FontFamilySwitch().regular,
        fontSize: "0.875rem",
        color: "text.primary",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",

    },

    dot: {
        height: "6px",
        width: "6px",
        color: "text.tertiary",
        m: "0px 8px 0px 8px "
    },
    textStack: {
        marginInlineStart: "8px"
    }
}