import { borderRadius } from "../../utils";
import { FontFamilySwitch } from "../../utils/font";

export const searchCardStyle = {
    main: {
        height: '232px',
        borderRadius: borderRadius,
        opacity: 1,
        position: 'relative',
        width: 'auto',
        backgroundColor: "#fff",
        boxShadow: "0px 0px 32px #0000000f",
        cursor: "pointer",
    },
    locateMain: {
        height: 'auto',
        borderRadius: borderRadius,
        opacity: 1,
        position: 'relative',
        width: 'fit-content',
        backgroundColor: "#fff",
        boxShadow: "0px 0px 32px #0000000f",
        cursor: "pointer"
    },
    image: {
        borderRadius: borderRadius,
        height: "150px",
        width: '-webkit-fill-available',
    },
    headerTextWrapped: {
        // textAlign: 'left',
        fontWeight: 600,
        fontSize:  "1rem",
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: "noWrap",
        // ml: "12px",
        // mr: "12px",
        // mt: "8px",
        fontFamily: FontFamilySwitch().medium
    },
    headerText: {
        textAlign: 'left',
        fontWeight: 600,
        fontSize:  "1rem",
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: "noWrap",
        // ml: "12px",
        // mt: "8px",
        // mr: "12px",
        fontFamily: FontFamilySwitch().medium


    },
    locationText: {
        textAlign: 'left',
        color: 'text.secondary',
        fontSize:  "0.75rem",
        // ml: "4px",
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: "noWrap",
        fontFamily: FontFamilySwitch().medium
    },
    locationBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        mt: "5px",
        // ml: "12px",
        // mr: "12px"
    },
    chip: {
        position: "absolute",
        top: "18px",
        right: "17px",
        backgroundColor: "#30C4AB",
        color: "#fff",
        fontSize:  "0.75rem",
        zIndex: 99,
        padding: "8px 16px",
        borderRadius: "50px",
        height: "32px"
    },
    roundIcon: {
        color: "#E4E8EE",
        height: "10px",
        width: "14px",
        m: "0px 4px 0px 7px"
    },
    iconBox: {
        display: "flex",
        alignItems: 'center',
        // ml: "8px",
        // mr: "8px",
        mt: "5px",
    },
    textBox: {
        padding: "16px"
    }
}