import {
  Box,
  Grid,
  Popover,
  ToggleButton,
  Typography,
  Stack,
  Select,
  TextField,
  MenuItem,
  IconButton,
  Hidden,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { TextBox } from "../textbox";
import { DropUp, DropdownIcon, PlusIcon, MinusIcon, RightDropdownIcon, LeftDropdownIcon } from "../../assets";
import { popoverRangeStyle } from "./style";
import { CustomButton } from "../Button";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { LocalStorageKeys, borderRadiusAlignments, remCalc } from "../../utils";
import { withTranslation } from "react-i18next";
// import { UseDebounce } from "../customHooks";
import { DialogBox } from "../dialogBox";
import { maxArea, minArea } from "../../utils/common";
import { TextBoxNew } from "../textboxNew";
import { DatePickerReact } from "../datePicker";
import moment from "moment";
import { maxOptions, minOptions } from "../../screens/enhancement_screen/listingSearch/components/utils";
import { DatePickerReactOld } from "../datePickerOld";
import { CompanyCard } from "../companyCard";

const PopoverRanger = ({
  startIcon = "",
  placeholder = "",
  updateState = () => false,
  type = "",
  options,
  fieldStyle = {},
  value = {},
  isSearched = false,
  clear = false,
  item = [],
  data = "",
  t = () => false,
  onSearchChange = () => { },
  response = "",
  area = '',
  NumberInputSize = '',
  label = "",
  onChange = () => false,
  minDate = "",
  selected = "",
  rightSide = false,
  companyOptions = [],
  setCompany = () => false,
  overAllData = {},
  dayMinusOne = false

}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const residential = JSON.parse(localStorage.getItem(LocalStorageKeys.residential_home_page))
  const metric = JSON.parse(localStorage.getItem(LocalStorageKeys.selected_metric))
  const currencyCode = JSON.parse(localStorage.getItem(LocalStorageKeys.selected_currency))
  // const commercial = JSON.parse(localStorage.getItem(LocalStorageKeys.commercial_home_page))
  // const commercialDuration = commercial?.selected?.duration;
  // const residentialDuration = residential?.selected?.duration;


  // const [textValue, setTextValue] = React.useState({
  //   bedroom: residential?.selected?.bedroom?.bed ? `${residential?.selected?.bedroom?.bed ? residential?.selected?.bedroom?.bed + " Bedroom " : "Any"}` : "" ||
  //     commercial?.selected?.bedroom?.bed ? `${commercial?.selected?.bedroom?.bed ? commercial?.selected?.bedroom?.bed + " Bedroom " : "Any"}` : "",
  //   duration: commercialDuration ? `${commercialDuration?.value} ${commercialDuration?.type}` : '' ||
  //     residentialDuration ? `${residentialDuration?.value} ${residentialDuration?.type}` : "",
  //   // duration: "",
  //   size: (commercial?.selected?.size?.minArea >= 0 || commercial?.selected?.size?.minArea || commercial?.selected?.size?.maxArea) ? `${commercial?.selected?.size?.minArea} - ${commercial?.selected?.size?.maxArea} ${commercial?.selected?.size?.type} ` : ""  ||
  //     (residential?.selected?.size?.minArea && residential?.selected?.size?.minArea) ? `${residential?.selected?.size?.minArea} - ${residential?.selected?.size?.maxArea} ${residential?.selected?.size?.type}` : "",
  //   category: 'any'
  // });
  let startDateNew = new Date(selected);
  let startDateFormat = moment(selected).format("DD-MMM-YYYY");
  let endDateMomentFormat = dayMinusOne ?
    moment(startDateNew).add(value?.duration?.value, value?.duration?.type).subtract(1, 'days').format("DD-MMM-YYYY") :
    moment(startDateNew).add(value?.duration?.value, value?.duration?.type).format("DD-MMM-YYYY");
  const [textValue, setTextValue] = React.useState({
    bedroom: `${value?.bedroom?.bed ?? "Any"} & ${value?.bedroom?.bath ?? "Any"}`,

    duration: `${value?.duration?.value ?? "12"} ${value?.duration?.type ?? "Month"}`,
    size: `${value?.size?.minArea ?? "0"} - ${value?.size?.maxArea}`,
    category: 'any',
    durationWithDate: `${startDateFormat} - ${endDateMomentFormat}`,
    price: `${value?.price?.min ?? 0} - ${value?.price?.max ?? "Any"}`,
    onlyMonth: moment(selected).format("DD-MMM-YYYY"),
    date: moment(selected).format("YYYY-MM-DD"),

  });
  // const debounce = UseDebounce()
  const handleSearch = (searchText, e) => {
    setAnchorEl(e)
    onSearchChange(searchText)
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const constructTextValue = (type, value, isSearched) => {
    if (type === "bedroom") {
      let bedroomText
      let bedText
      let bathText
      if (isSearched) {
        bedText = (value?.bedroom?.bed === "Any" || value?.bedroom?.bed === "Studio") ? value?.bedroom?.bed : value?.bedroom?.bed ? `${value?.bedroom?.bed + " Beds "} ` : "";
        bathText = (value?.bedroom?.bath === "Any") ? value?.bedroom?.bath : value?.bedroom?.bath ? `${value?.bedroom?.bath + " Bath "} ` : "";
        bedroomText = `${bedText ?? ""} & ${bathText ?? ""}`
      } else {
        bedText = (value?.bedroom?.bed === "Any" || value?.bedroom?.bed === "Studio") ? value?.bedroom?.bed : value?.bedroom?.bed ? `${value?.bedroom?.bed + " Beds "} ` : "";
        bathText = (value?.bedroom?.bath === "Any") ? value?.bedroom?.bath : value?.bedroom?.bath ? `${value?.bedroom?.bath + " Bath "} ` : "";
        bedroomText = `${bedText ?? ""} & ${bathText ?? ""}`
      }
      setTextValue({ ...textValue, bedroom: bedroomText });
      (!isSearched) && handleClose()
    } else if (type === "duration") {
      let durationText = value?.duration?.value ? `${value?.duration?.value} ${value?.duration?.type} ` : "";
      let startDate1 = new Date(selected);
      let startDate = moment(selected).format("DD-MMM-YYYY");
      let endDateMoment = dayMinusOne ? moment(startDate1).add(value?.duration?.value, value?.duration?.type).subtract(1, 'days').format("DD-MMM-YYYY") :
        moment(startDate1).add(value?.duration?.value, value?.duration?.type).format("DD-MMM-YYYY");
      setTextValue({ ...textValue, duration: durationText, durationWithDate: `${startDate} - ${endDateMoment}` });
      (!isSearched) && handleClose()

    }
    else if (type === "onlyMonth") {
      let startDate = moment(selected).format("DD-MMM-YYYY");
      setTextValue({ ...textValue, onlyMonth: startDate });

      (!isSearched) && handleClose()

    }
    else if (type === "date") {
      let startDate = moment(selected).format("DD-MMM-YYYY");
      setTextValue({ ...textValue, date: startDate });

      (!isSearched) && handleClose()

    }
    else if (type === "size") {
      let sizeText = (value?.size?.minArea >= 0 || value?.size?.minArea || value?.size?.maxArea) ? `${value?.size?.minArea} - ${value?.size?.maxArea}` : ""
      setTextValue({ ...textValue, size: sizeText });
      (!isSearched) && handleClose()
    }
    else if (type === "category") {
      let sizeText = `${value?.size?.minArea} `
      setTextValue({ ...textValue, size: sizeText });
      (!isSearched) && handleClose()
    }
    else if (type === "price") {
      let priceText = (value?.price?.min >= 0 || value?.price?.min || value?.price?.max) ? `${value?.price?.min}  - ${value?.price?.max}` : ""

      setTextValue({ ...textValue, price: priceText });
      (!isSearched) && handleClose()
    }

  };

  const Container = styled(Stack)({
    padding: 16,
    // boxShadow: "0px 8px 24px #0717411F",
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
  });

  const CustomSelect = styled(Select)(({ theme }) => ({
    minWidth: "78px",
    backgroundColor: "#F2F4F7",
    borderRadius: 8,
    height: "40px ",
    "& .MuiSelect-select": {
      paddingBlock: "4.5px",
      color: theme.palette.text.primary,
      fontSize: remCalc(14),
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F2F4F7",
      borderRadius: 8,
      height: "40px ",
      border: 'none'
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F2F4F7",
      },
    },
  }));
  const NumberInputPrice = React.useCallback(({ value, onChange, key, options }) => {
    return (
      <>
        <Select
          value={value}
          onChange={onChange}
          sx={{
            width: "130px",
            "& select": {
              maxWidth: 77,
              maxHeight: 5,
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderRadius: "10px",
            },
            "&.MuiInputBase-root.MuiOutlinedInput-root": {
              height: "40px !important",
            }
          }}
        >
          {options?.map((val) => {
            return (
              <MenuItem value={val?.value}>{val?.label}</MenuItem>
            )
          })}
        </Select>
      </>
    )
  }, [])
  // const NumberInput = ({ value, onChange }) => (
  //   <TextField
  //     value={value}
  //     onChange={onChange}
  //     type={"number"}
  //     sx={{ ...fieldStyle, ...popoverRangeStyle?.NumberInput, }}
  //     onKeyPress={(e) => {
  //       if (["e", "E"]?.includes(e.key)) {
  //         e.preventDefault();
  //       }
  //     }}
  //   />
  // );


  const SmallNumber = ({ value, onChange }) => (
    <TextField
      value={value}
      onChange={(e) => updateState("size", e.target.value)}
      type={"number"}
      sx={{ ...popoverRangeStyle?.NumberInput, ...fieldStyle }}
      onKeyPress={(e) => {
        if (["e", "E"]?.includes(e.key)) {
          e.preventDefault();
        }
      }}
    />
  );
  React.useEffect(() => {
    if (isSearched) {
      constructTextValue(type, value, isSearched)
    }
    if (clear) {
      setTextValue({
        bedroom: "",
        duration: "",
        size: "",
        category: 'any',
        price: "",
        date: "",


      })
    }
    //  eslint-disable-next-line
  }, [isSearched, value])
  // Toggle Between Popovers
  const selectPopover = (type) => {
    switch (type) {
      case "bedroom":
        return (
          <>
            <Typography sx={popoverRangeStyle.toggleHeader}>{t("Choose Bedroom")}</Typography>
            <Grid container sx={popoverRangeStyle.gap}>
              {options?.bedroom?.map((val) => (
                <ToggleButton
                  value={value?.bedroom?.bed}
                  sx={(value?.bedroom?.bed === val || (val === '4+' && value?.bedroom?.bed === 4))  
                    ? popoverRangeStyle.toggleSelected
                    : popoverRangeStyle.toggle}
                    onChange={() => updateState("bedroom", "bed", val === '4+' ? 4 : val)}
                // onClick={() => {
                //   constructTextValue("bedroom", val)
                // }}
                >
                  {val === 4 ? '4+' : val}
                </ToggleButton>
              ))}
            </Grid>
            <Typography sx={popoverRangeStyle.toggleHeader}>Bath</Typography>
            <Grid container sx={popoverRangeStyle.gap}>
              {options?.bath?.map((val) => (
                <ToggleButton
                  value={value?.bedroom?.bath}
                  sx={(value?.bedroom?.bath === val || (val === '4+' && value?.bedroom?.bath === 4))  
                    ? popoverRangeStyle.toggleSelected
                    : popoverRangeStyle.toggle}
                    onChange={() => updateState("bedroom", "bath", val === '4+' ? 4 : val)}
                >
                  {val === 4 ? '4+' : val}
                </ToggleButton>
              ))}
            </Grid>
            <CustomButton
              color="secondary"
              variant="contained"
              label="Apply"
              onClick={() => constructTextValue("bedroom", value)}
              rootStyle={popoverRangeStyle.maxWidth}
            />
          </>
        );
      case "duration":
        return (
          <>
            <Typography sx={popoverRangeStyle.toggleHeader}>
              {t("Enter Duration")}
            </Typography>
            <Box sx={popoverRangeStyle.gap}>
              <Box width={"45px"}>
                <TextBox
                  isSearch
                  fieldStyle={popoverRangeStyle.texField}
                  type="number"
                  onChange={(e) =>
                    updateState("duration", "value", e.target.value)
                  }
                  value={value?.duration?.value}
                />
              </Box>
              {options?.map((val) => (
                <ToggleButton
                  value={value?.duration?.type}
                  sx={
                    value?.duration?.type === val
                      ? popoverRangeStyle.duratonToggleSelected
                      : popoverRangeStyle.duratonToggle
                  }
                  onChange={() => updateState("duration", "type", val)}
                >
                  {val}
                </ToggleButton>
              ))}
            </Box>
            <Box sx={popoverRangeStyle.gap}>
              <DatePickerReact onChange={onChange} selected={selected} minDate={minDate} />
            </Box>
            <CustomButton
              color="secondary"
              variant="contained"
              label="Apply"
              onClick={() => constructTextValue("duration", value)}
              rootStyle={popoverRangeStyle.maxWidth}
            />
          </>
        );
      case "size":
        return (
          <>
            <Typography sx={popoverRangeStyle.toggleHeader}>
              {t("Choose Size")}
            </Typography>
            <Container direction={"row"} alignItems={"center"} gap={2}>


              <Box style={popoverRangeStyle.sizeBox}  >
                <NumberInputSize options={minArea}
                  placeholder={t("Min Area")}
                  onChange={(e) => updateState("size", "minArea", e.target.value)}
                  value={value?.size?.minArea} />

                <Typography fontSize={12} color={"text.secondary'"}>
                  {" "}
                  -{" "}
                </Typography>

                <NumberInputSize options={maxArea}
                  placeholder={t("Max Area")}
                  onChange={(e) => updateState("size", "maxArea", e.target.value)}
                  value={value?.size?.maxArea} />
              </Box>

              <CustomSelect
                onChange={(e) => updateState("size", "type", e.target.value)}
                value={value?.size?.type}
                MenuProps={{ PaperProps: { sx: { maxHeight: "170px", overflow: "auto" } } }}
                labelId="demo-simple-select-disabled-label"
                id="demo-simple-select-disabled"
                disabled
              >
                <MenuItem value={value?.size?.type} disabled={value?.size?.type}>{value?.size?.type}</MenuItem>
              </CustomSelect>
            </Container>
            <CustomButton
              color="secondary"
              variant="contained"
              label="Apply"
              onClick={() => constructTextValue("size", value)}
              rootStyle={popoverRangeStyle.maxWidth}
            />
          </>
        );
      case "member":
        return (
          <>
            <Box
              sx={popoverRangeStyle.memberBox}
            >
              <Typography sx={popoverRangeStyle.duration}>
                {t("Choose Members")}
              </Typography>
              <Grid container spacing={2}>
                <Grid item>
                  <Typography>{t("Adult")}</Typography>
                  <Typography sx={popoverRangeStyle.subtile}>
                    {t("Age Above 13")}
                  </Typography>
                </Grid>
                <Grid item sx={popoverRangeStyle.member}>
                  <IconButton>
                    <MinusIcon />
                  </IconButton>
                  <SmallNumber placeholder={"00"} value={value?.member?.adult} />
                  <IconButton>
                    <PlusIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item>
                  <Typography>{t("Children")}</Typography>
                  <Typography sx={popoverRangeStyle.subtile}>
                    {t("Age Below 13")}
                  </Typography>
                </Grid>
                <Grid item sx={popoverRangeStyle.member}>
                  <IconButton>
                    <MinusIcon />
                  </IconButton>
                  <SmallNumber placeholder={"00"} value={value?.member?.child} />
                  <IconButton>
                    <PlusIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
            <CustomButton
              color="secondary"
              variant="contained"
              label="Apply"
              onClick={() => constructTextValue("member", value)}
              rootStyle={popoverRangeStyle.maxWidth}
            />
          </>
        );
      case "category":
        return (
          <>
            <Typography sx={popoverRangeStyle.toggleHeader}>
              {t("Choose Type")}
            </Typography>
            <Box
              sx={popoverRangeStyle.memberBox}
            >
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  sx={popoverRangeStyle.formlabel}
                >
                  {item?.length > 0 ?
                    <>
                      {item?.map((val) => {
                        return (
                          <FormControlLabel onChange={() => {
                            updateState("category", "category", val)
                            handleClose()
                          }} value={val?.value} control={<Radio checked={data === val?.value} />} label={val?.label}
                            sx={popoverRangeStyle.formlabel}
                          />
                        )
                      })}
                    </>
                    :
                    <Box sx={popoverRangeStyle.noDataFound}>
                      {/* <NoDataImageMob width={"100%"} height={150} /> */}
                      <Typography sx={popoverRangeStyle.noDataFoundText}>{t("No Category Found")}</Typography>
                    </Box>
                  }
                </RadioGroup>
              </FormControl>
            </Box>
          </>
        );
      case "price":
        return (
          <>
            <Typography sx={popoverRangeStyle.toggleHeader}>
              {t("Choose Price Range")}
            </Typography>
            <Container direction={"row"} alignItems={"center"} gap={2}>


              <Box style={popoverRangeStyle.sizeBox}  >
                <NumberInputPrice options={minOptions}
                  placeholder={t("Min")}
                  onChange={(e) => updateState("price", "min", e.target.value)}
                  value={value?.price?.min} />

                <Typography fontSize={12} color={"text.secondary'"}>
                  {" "}
                  -{" "}
                </Typography>

                <NumberInputPrice options={maxOptions}
                  placeholder={t("Max")}
                  onChange={(e) => updateState("price", "max", e.target.value)}
                  value={value?.price?.max} />
              </Box>


            </Container>
            <CustomButton
              color="secondary"
              variant="contained"
              label="Apply"
              onClick={() => constructTextValue("price", value)}
              rootStyle={popoverRangeStyle.maxWidth}
            />

          </>
        )
      case "onlyMonth":
        return (
          <>
            <Box sx={popoverRangeStyle.gap}>
              <DatePickerReact onChange={onChange} selected={selected} minDate={minDate} />
            </Box>
            <CustomButton
              color="secondary"
              variant="contained"
              label="Apply"
              onClick={() => constructTextValue("onlyMonth", value)}
              rootStyle={popoverRangeStyle.maxWidth}
            />
          </>
        );
      case "date":
        return (
          <>
            <Box sx={popoverRangeStyle.gap}>
              <DatePickerReactOld onChange={onChange} selected={selected} minDate={minDate} />
            </Box>
            <CustomButton
              color="secondary"
              variant="contained"
              label="Apply"
              onClick={() => constructTextValue("date", value)}
              rootStyle={popoverRangeStyle.maxWidth}
            />
          </>
        );
      case "company":
        return (
          <>
            <Grid container sx={popoverRangeStyle.gap1}>
              {companyOptions?.map((val) => (
                <Grid item xs={12}>
                  <CompanyCard
                    src={val?.src}
                    Textlabel={val?.label}
                    descriptionlabel={val?.description}
                    borderHide
                    borderBottom
                    selected={val?.label === value?.label ? true : false}
                    onClick={() => {
                      setCompany({
                        ...overAllData,
                        selected: val
                      })
                      handleClose()
                    }}

                  />
                </Grid>
              ))}
            </Grid>


          </>
        );
      default:
        break;
    }
  };
  const selectTextbox = (type) => {
    switch (type) {
      case "bedroom":
        return (
          <TextBoxNew
            isSearch
            label={label}
            placeholder={placeholder}
            fieldStyle={fieldStyle}
            value={textValue.bedroom}
            endAdornment={
              rightSide ? (anchorEl ? <LeftDropdownIcon rootStyle={popoverRangeStyle.mt2} /> : <RightDropdownIcon rootStyle={popoverRangeStyle.mt2} />) : anchorEl ? (
                <DropUp rootStyle={popoverRangeStyle.mt2} />
              ) : (
                <DropdownIcon rootStyle={popoverRangeStyle.mt2} />
              )
            }
            startAdornment={startIcon}
          />
        );
      case "duration":
        return (
          <TextBoxNew
            isSearch
            label={`${label} (${textValue?.duration})`}
            placeholder={placeholder}
            fieldStyle={fieldStyle}
            value={textValue.durationWithDate}
            endAdornment={
              rightSide ? (anchorEl ? <LeftDropdownIcon rootStyle={popoverRangeStyle.mt2} /> : <RightDropdownIcon rootStyle={popoverRangeStyle.mt2} />) : anchorEl ? (
                <DropUp rootStyle={popoverRangeStyle.mt2} />
              ) : (
                <DropdownIcon rootStyle={popoverRangeStyle.mt2} />
              )
            }
            startAdornment={startIcon}
          />
        );
      case "size":
        return (
          <TextBoxNew
            isSearch
            label={label}
            placeholder={placeholder}
            fieldStyle={fieldStyle}
            value={`${textValue.size} ${metric?.name}`}
            endAdornment={
              rightSide ? (anchorEl ? <LeftDropdownIcon rootStyle={popoverRangeStyle.mt2} /> : <RightDropdownIcon rootStyle={popoverRangeStyle.mt2} />) : anchorEl ? (
                <DropUp rootStyle={popoverRangeStyle.mt2} />
              ) : (
                <DropdownIcon rootStyle={popoverRangeStyle.mt2} />
              )
            }
            startAdornment={startIcon}
          />
        );
      case "member":
        return (
          <TextBox
            isSearch
            label={label}
            placeholder={placeholder}
            fieldStyle={fieldStyle}
            value={textValue.member}
            endAdornment={
              rightSide ? (anchorEl ? <LeftDropdownIcon rootStyle={popoverRangeStyle.mt2} /> : <RightDropdownIcon rootStyle={popoverRangeStyle.mt2} />) : anchorEl ? (
                <DropUp rootStyle={popoverRangeStyle.mt2} />
              ) : (
                <DropdownIcon rootStyle={popoverRangeStyle.mt2} />
              )
            }
            startAdornment={startIcon}
          />
        );
      case "category":
        return (
          <TextBoxNew
            isSearch
            label={label}
            onChange={(e) =>
              handleSearch(e.target.value, e.currentTarget)}
            placeholder={placeholder}
            fieldStyle={fieldStyle}
            value={value ?? textValue.member}
            endAdornment={
              rightSide ? (anchorEl ? <LeftDropdownIcon rootStyle={popoverRangeStyle.mt2} /> : <RightDropdownIcon rootStyle={popoverRangeStyle.mt2} />) : anchorEl ? (
                <DropUp rootStyle={popoverRangeStyle.mt2} />
              ) : (
                <DropdownIcon rootStyle={popoverRangeStyle.mt2} />
              )
            }
            startAdornment={startIcon}
          />
        );
      case "price":
        return (
          <TextBoxNew
            isSearch
            label={label}
            placeholder={placeholder}
            fieldStyle={fieldStyle}
            value={`${textValue.price} ${currencyCode?.currency_code}`}
            endAdornment={
              rightSide ? (anchorEl ? <LeftDropdownIcon rootStyle={popoverRangeStyle.mt2} /> : <RightDropdownIcon rootStyle={popoverRangeStyle.mt2} />) : anchorEl ? (
                <DropUp rootStyle={popoverRangeStyle.mt2} />
              ) : (
                <DropdownIcon rootStyle={popoverRangeStyle.mt2} />
              )
            }
            startAdornment={startIcon}
          />
        );
      case "onlyMonth":
        return (
          <TextBoxNew
            isSearch
            label={label}
            placeholder={placeholder}
            fieldStyle={fieldStyle}
            value={textValue.onlyMonth}
            endAdornment={
              rightSide ? (anchorEl ? <LeftDropdownIcon rootStyle={popoverRangeStyle.mt2} /> : <RightDropdownIcon rootStyle={popoverRangeStyle.mt2} />) : anchorEl ? (
                <DropUp rootStyle={popoverRangeStyle.mt2} />
              ) : (
                <DropdownIcon rootStyle={popoverRangeStyle.mt2} />
              )
            }
            startAdornment={startIcon}
          />
        );
      case "date":
        return (
          <TextBoxNew
            isSearch
            label={label}
            placeholder={placeholder}
            fieldStyle={fieldStyle}
            value={textValue.date}
            endAdornment={
              rightSide ? (anchorEl ? <LeftDropdownIcon rootStyle={popoverRangeStyle.mt2} /> : <RightDropdownIcon rootStyle={popoverRangeStyle.mt2} />) : anchorEl ? (
                <DropUp rootStyle={popoverRangeStyle.mt2} />
              ) : (
                <DropdownIcon rootStyle={popoverRangeStyle.mt2} />
              )
            }
            startAdornment={startIcon}
          />
        );
      case "company":
        return (
          <CompanyCard
            src={value?.src}
            Textlabel={value?.label}
            descriptionlabel={value?.description}
            arrow
            endAdornment={
              (anchorEl ? <LeftDropdownIcon rootStyle={popoverRangeStyle.mt2} /> : <RightDropdownIcon rootStyle={popoverRangeStyle.mt2} />)
            }
          />
        );
      default:
        break;
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Box sx={popoverRangeStyle.main}>
      <Box sx={popoverRangeStyle.textFieldBox} onClick={handleClick}>
        <Box sx={popoverRangeStyle.textFieldBox1}>{selectTextbox(type)}</Box>
      </Box>


      <>
        <Hidden smDown>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: rightSide ? "top" : 'bottom',
              horizontal: rightSide ? 'right' : 'left', // Conditionally set horizontal alignment
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: rightSide ? 'right' : 'left', // Conditionally set horizontal alignment
            }}
            sx={rightSide ? {
              ...popoverRangeStyle.dialog, "& .MuiPopover-paper": {
                left: "27% !important",
                borderRadius: borderRadiusAlignments.borderEight,
                boxShadow: '0px 2px 12px #00000014'

              }
            } : popoverRangeStyle.dialog}
            disableAutoFocus
            disableEnforceFocus
            disableRestoreFocus
          >
            <Box sx={popoverRangeStyle.toggleContainer}>{selectPopover(type)}</Box>
          </Popover>
        </Hidden>
        <Hidden smUp>
          <DialogBox
            open={anchorEl}
            component={<>
              <Box sx={popoverRangeStyle.memberBoxDialog}>
                {selectPopover(type)}
              </Box>
            </>}
            maxWidth="xs"
            header={t(type.charAt(0).toUpperCase() + type.slice(1))}
            handleClose={() => handleClose()}
          />

        </Hidden>
      </>
    </Box>
  );
};

export default withTranslation("popover")(PopoverRanger);
