import { borderRadius } from "../../utils";
import { FontFamilySwitch } from "../../utils/font";

export const UnitCardsStyle = {
    mainBox: {
        height: "168px",
        padding: "30px",
        backgroundColor: "#FFF8E7",
        borderRadius: borderRadius,
    },
    countText: {
        color: "#FFC429",
        fontFamily: FontFamilySwitch().extraBold,
        fontSize: "50px",
    },
    propertyText: {
        color: "text.primary",
        fontFamily: FontFamilySwitch().extraBold,
        fontSize: "20px",
    },
    imageBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    }
}