import { borderRadius, borderRadiusAlignments } from "../../utils";
import { FontFamilySwitch } from "../../utils/font";

export const MyBookingCardStyle = {
  mainBox: {
    display: "flex",
    backgroundColor: "primary.contrastText",
    padding: "4px",
    alignItems: "center",
    borderRadius: borderRadius
  },
  mainimage: {
    display: "flex",
    alignItems: "center",
    padding: "16px 0px 16px 8px",
  },
  cardcontent: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  image: {
    height: "48px",
    width: "48px",
    borderRadius: borderRadiusAlignments?.borderTwelve
  },
  text: {
    padding: "12px",
  },
  text1: {
    fontFamily: FontFamilySwitch().medium,
    fontSize: "1rem",
    color: "text.primary",
    // lineHeight: 1.7,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  text2: {
    fontSize: "0.875rem !important",
    fontFamily: FontFamilySwitch().regular,
    color: "primary.light1",
    // lineHeight: 1.7,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  date: {
    padding: "12px",
    display: "flex",
  },
  textdate: {
    fontSize: "0.875rem !important",
    fontFamily: FontFamilySwitch().medium,
    color: "primary.light1",
  },
  chipbooked: {
    backgroundColor: "#5AC782",
    color: "primary.contrastText",
    fontSize: "0.625rem",
    fontFamily: FontFamilySwitch().medium,
    borderRadius: borderRadius,
    height: "24px",
  },
  chipcancel: {
    backgroundColor: "#FF4B4B",
    color: "primary.contrastText",
    fontSize: "0.625rem",
    fontFamily: FontFamilySwitch().medium,
    borderRadius: borderRadius,
    height: "24px",
  },
  chipcontacted: {
    backgroundColor: "#78B1FE",
    color: "primary.contrastText",
    fontSize: "0.625rem",
    fontFamily: FontFamilySwitch().medium,
    borderRadius: borderRadius,
    height: "24px",
  },
  textcontent: {
    display: "flex",
    alignItems: "center",
    width: " 100%",
    justifyContent: "space-between",
  },
  textbox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: " 100%",
  },
  bookingbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: " flex-end",
  },
  button1: {
    height: "40px",
    borderRadius: "311px ",
    color: "#FF4B4B",
    fontFamily: FontFamilySwitch().medium,
    fontSize: "0.75rem",
    border: "1px solid #E4E8EE",
    "&:hover": {
      border: "1px solid #E4E8EE",
      backgroundColor: "#fff",
    },
  },
  button2: {
    height: "40px",
    borderRadius: "311px ",
    color: "#1F74EA",
    fontFamily: FontFamilySwitch().medium,
    fontSize: "0.75rem",
    border: "1px solid #E4E8EE",
    marginInlineStart:"16px",
    // float:"right",
    "&:hover": {
      border: "1px solid #E4E8EE",
      backgroundColor: "#fff",
    },
  },
  mainbutton: {
    display: "flex",
    gap: "20px",
    padding: "12px",
    justifyContent: "flex-end",
    width: "290px",
  },
  chipbox: {
    padding: "12px",
    display: "flex",
  },
  Divider: {
    mr: "14px",
    ml: '14px'
  },
  root: {
    padding: "16px"
  },
  titleStack: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    marginInlineStart: "16px"
  },
  buttonStacks: {
    float: "right !important",

  },
  buttonStacksArabic: {
    float: "left !important",

  }
};
