import { borderRadius } from "../../utils";
import { FontFamilySwitch } from "../../utils/font";

export const propertyCardStyle = {
   searchMain: {
      width: 'auto',
      // height: '150px',
      position: 'relative',
      borderRadius: borderRadius,
      opacity: 1,
      boxShadow: "0px 0px 32px #0000000f",
      cursor: "pointer"
   },
   propertyMain: {
      width: 'auto',
      // height: '300px',
      position: 'relative',
      borderRadius: borderRadius,
      opacity: 1,
      boxShadow: "0px 0px 32px #0000000f",
      cursor: "pointer"

   },
   text: {
      position: "absolute",
      bottom: "0px",
      padding: '7px',
      color: "#fff",
      display: "flex",
      justifyContent: "space-between",
      alignItems: 'center',
      borderBottomLeftRadius: borderRadius,
      borderBottomRightRadius: borderRadius,
      width: '100%',
      backgroundImage: 'linear-gradient(180deg, #091B2900,#000000BF)',
   },
   propertyText: {
      position: "absolute",
      bottom: "0px",
      padding: '16px',
      borderBottomLeftRadius: borderRadius,
      borderBottomRightRadius: borderRadius,
      color: "#fff",
      display: "flex",
      justifyContent: "space-between",
      alignItems: 'center',
      width: '100%',
      backgroundImage: 'linear-gradient(180deg, #091B2900,#000000BF)',

   },
   desc: {
      position: 'absolute',
      bottom: '1px',
      fontSize: "0.75rem !important",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden"
   },
   desc1: {
      position: 'absolute',
      bottom: '8px',
      fontSize: "0.75rem !important",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      mt: 32,
   },
   propertyHeader: {
      fontFamily: FontFamilySwitch().medium,
      fontSize: "1rem !important",
      marginBottom: '8px',
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",

   },
   propertyHeaderwrap: {
      fontFamily: FontFamilySwitch().medium,
      fontSize: "1rem !important",
      // marginBottom: '10px',
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",

   },
   height2: {
      height: "150px",
      width: "100%",
      borderRadius: borderRadius,
   },
   height: {
      height: "100%",
      width: "100%",
      borderRadius: borderRadius,
   },
   avatarBox: {
      display: "flex",
      justifyContent: "center",
   },
   avatar: {
      display: "flex",
      justifyContent: "center",

   }

}