import { Delete } from "@mui/icons-material";
import { BookingIcon, SignOutIcon } from "../../../assets";
import { MyBooking } from "../../../assets/components/myBooking";
import { AppRoutes } from "../../../router/routes";
import FavoriteIcon from "@mui/icons-material/Favorite";

// Language Switcher Details
export const LanguageDetails = [
  {
    url: "",
    label: "English",
    label2: "English",
    value: "en",
  },
  // {
  //   url: "",
  //   label: "Spanish",
  //   label2: "spanish",
  //   value: "es",
  // },
  // {
  //   url: "",
  //   label: "French",
  //   label2: "french",
  //   value: "fr",
  // },
  // {
  //   url: "",
  //   label: "Arabic",
  //   label2: "Arabic",
  //   value: "ar",
  // },
];
// userSnippet Details
export const userSnippet = [
  {
    label: "My Shortlist",
    route: AppRoutes?.myShortlist,
    icon: <img src="/images/myShortlist.png" alt="" />,
    id: 1,
  },
  {
    label: "My Favorites",
    route: AppRoutes?.shortlist,
    icon: <FavoriteIcon sx={{ color: "#98A0AC" }} />,
    id: 2,
  },
  {
    label: "My Bookings",
    route: AppRoutes?.confirmBooking,
    icon: <MyBooking />,
    id: 3,
  },
  {
    label: "Delete Account",
    route: "delete",
    icon: <Delete sx={{ color: "#98a0ac" }} />,
    id: 4,
  },
  {
    label: "Sign Out",
    route: "SignOut",
    icon: <SignOutIcon />,
    id: 5,
  },
];

export const userSnippetMob = [
  {
    label: "My Shortlist",
    route: AppRoutes?.myShortlist,
    icon: <BookingIcon />,
    id: 1,
  },
  {
    label: "My Favorites",
    route: AppRoutes?.shortlist,
    icon: <FavoriteIcon sx={{ color: "#98A0AC" }} />,
    id: 2,
  },
  {
    label: "My Bookings",
    route: AppRoutes?.confirmBooking,
    icon: <MyBooking />,
    id: 3,
  },
  {
    label: "Delete Account",
    route: "delete",
    icon: <Delete sx={{ color: "#98a0ac" }} />,
    id: 4,
  },
];
export const logout = [
  {
    label: "Sign Out",
    route: "SignOut",
    icon: <SignOutIcon />,
  },
];
