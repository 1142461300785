import { Box, Button, Typography } from "@mui/material"
import React from "react"
import { refreshCacheAndReload } from "../../utils"
import { DialogBox } from "../dialogBox"
import { FontFamilySwitch } from "../../utils/font"

export const VersionAlert = ({
    state = {},
    handleVersionAlert = () => false,
}) => {
    const SvgComponent = (props) => (
        <svg xmlns="http://www.w3.org/2000/svg" width={224} height={191} {...props}>
            <defs>
                <filter
                    id="a"
                    width={186}
                    height={151}
                    x={19}
                    y={40}
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset />
                    <feGaussianBlur result="blur" stdDeviation={12} />
                    <feFlood floodOpacity={0.102} />
                    <feComposite in2="blur" operator="in" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter
                    id="b"
                    width={204}
                    height={162}
                    x={10}
                    y={23}
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset />
                    <feGaussianBlur result="blur-2" stdDeviation={12} />
                    <feFlood floodOpacity={0.102} />
                    <feComposite in2="blur-2" operator="in" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter
                    id="c"
                    width={224}
                    height={178}
                    x={0}
                    y={0}
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset />
                    <feGaussianBlur result="blur-3" stdDeviation={12} />
                    <feFlood floodOpacity={0.102} />
                    <feComposite in2="blur-3" operator="in" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g data-name="Group 117098" transform="translate(-567 -208)">
                <rect
                    width={190}
                    height={144}
                    fill="#f8f9fa"
                    data-name="Rectangle 58516"
                    rx={16}
                    transform="translate(584 231)"
                />
                <path
                    fill="#ffc429"
                    d="M593.099 245.813a.837.837 0 0 1-.8-.581l-1.633-5.078-5.078-1.633a.837.837 0 0 1 0-1.593l5.078-1.633 1.637-5.082a.837.837 0 0 1 1.593 0l1.633 5.078 5.078 1.633a.837.837 0 0 1 0 1.593l-5.078 1.633-1.633 5.078a.837.837 0 0 1-.797.585Zm-10.877-10.044a.836.836 0 0 1-.794-.572l-1.053-3.159-3.159-1.053a.837.837 0 0 1 0-1.588l3.159-1.053 1.053-3.159a.837.837 0 0 1 1.588 0l1.053 3.159 3.159 1.053a.837.837 0 0 1 0 1.588l-3.159 1.053-1.053 3.159a.836.836 0 0 1-.794.572Zm-.837 12.272a.836.836 0 0 1-.794-.572l-.847-2.532-2.528-.844a.837.837 0 0 1 0-1.588l2.528-.843.845-2.532a.836.836 0 0 1 .794-.572.836.836 0 0 1 .794.572l.844 2.532 2.532.844a.837.837 0 0 1 0 1.588l-2.532.844-.844 2.532a.836.836 0 0 1-.792.571Z"
                    data-name="icons8-shine (2)"
                />
                <g data-name="Group 117098">
                    <g filter="url(#a)" transform="translate(567 208)">
                        <rect
                            width={114}
                            height={79}
                            fill="#fff"
                            data-name="Rectangle 58526"
                            rx={8}
                            transform="translate(55 76)"
                        />
                    </g>
                    <g filter="url(#b)" transform="translate(567 208)">
                        <rect
                            width={132}
                            height={90}
                            fill="#fff"
                            data-name="Rectangle 58525"
                            rx={8}
                            transform="translate(46 59)"
                        />
                    </g>
                    <g filter="url(#c)" transform="translate(567 208)">
                        <rect
                            width={152}
                            height={106}
                            fill="#fff"
                            data-name="Rectangle 58517"
                            rx={8}
                            transform="translate(36 36)"
                        />
                    </g>
                    <path
                        fill="#c9d9f6"
                        d="M611 244h136a8 8 0 0 1 8 8v5H603v-5a8 8 0 0 1 8-8Z"
                        data-name="Rectangle 58518"
                    />
                    <g
                        fill="#e9ecef"
                        data-name="Group 117099"
                        opacity={0.41}
                        transform="translate(-8 -15)"
                    >
                        <rect
                            width={114}
                            height={14}
                            data-name="Rectangle 58520"
                            rx={4}
                            transform="translate(630 311)"
                        />
                        <rect
                            width={31}
                            height={14}
                            data-name="Rectangle 58522"
                            rx={4}
                            transform="translate(630 286)"
                        />
                        <rect
                            width={31}
                            height={14}
                            data-name="Rectangle 58523"
                            rx={4}
                            transform="translate(672 286)"
                        />
                        <rect
                            width={31}
                            height={14}
                            data-name="Rectangle 58524"
                            rx={4}
                            transform="translate(713 286)"
                        />
                        <rect
                            width={114}
                            height={14}
                            data-name="Rectangle 58521"
                            rx={4}
                            transform="translate(630 336)"
                        />
                    </g>
                    <g data-name="Group 117100" transform="translate(-6 -7)">
                        <circle
                            cx={35}
                            cy={35}
                            r={35}
                            fill="#fff"
                            data-name="Ellipse 131041"
                            opacity={0.73}
                            transform="translate(650 275)"
                        />
                        <circle
                            cx={27}
                            cy={27}
                            r={27}
                            fill="#eff5e6"
                            data-name="Ellipse 131042"
                            transform="translate(658 283)"
                        />
                        <path
                            fill="#72a820"
                            d="M685 290a20 20 0 1 0 20 20 20.023 20.023 0 0 0-20-20Zm4.5 5.08a1.5 1.5 0 1 1 1.39 2.079 1.511 1.511 0 0 1-1.39-2.079Zm-4.55-2.08a1.5 1.5 0 1 1-1.5 1.5 1.5 1.5 0 0 1 1.5-1.5Zm-15.67 10.53a1.5 1.5 0 1 1 1.38 2.07 1.669 1.669 0 0 1-.57-.109 1.5 1.5 0 0 1-.81-1.961Zm-1.28 6.5a1.5 1.5 0 1 1 1.5 1.5 1.5 1.5 0 0 1-1.5-1.5Zm3.26 7.31a1.459 1.459 0 0 1-.57.12 1.5 1.5 0 1 1 1.38-2.08 1.493 1.493 0 0 1-.81 1.96Zm1.69-19.33a1.5 1.5 0 1 1 1.06 2.559 1.525 1.525 0 0 1-1.06-.439 1.5 1.5 0 0 1 0-2.12Zm2.17 24.03a1.507 1.507 0 0 1-1.06.439 1.5 1.5 0 0 1-1.07-2.559 1.503 1.503 0 1 1 2.13 2.12Zm3.33-27.73a1.502 1.502 0 0 1 1.16 2.771h-.01a1.625 1.625 0 0 1-.57.109 1.5 1.5 0 0 1-.58-2.88Zm2.02 30.59a1.505 1.505 0 0 1-1.39.931 1.647 1.647 0 0 1-.57-.11 1.5 1.5 0 1 1 1.96-.821Zm4.55 2.1a1.5 1.5 0 1 1 1.5-1.5 1.5 1.5 0 0 1-1.5 1.5Zm1.48-19.879v9.379a1.5 1.5 0 0 1-3 0v-9.379l-1.939 1.939a1.5 1.5 0 1 1-2.121-2.121l4.5-4.5a1.5 1.5 0 0 1 2.121 0l4.5 4.5a1.5 1.5 0 0 1-2.122 2.122Zm5.02 18.589a1.657 1.657 0 0 1-.58.109 1.491 1.491 0 1 1 .58-.109Zm3.34-27.771a1.5 1.5 0 1 1 1.06 2.56 1.526 1.526 0 0 1-1.06-.44 1.5 1.5 0 0 1 0-2.12Zm2.17 24.081v-.01a1.508 1.508 0 0 1-2.12.009 1.5 1.5 0 1 1 2.12 0Zm1.69-19.39a1.5 1.5 0 1 1 .58 2.88 1.482 1.482 0 0 1-1.38-.92 1.5 1.5 0 0 1 .8-1.96Zm1.99 13.87a1.5 1.5 0 0 1-1.39.93 1.459 1.459 0 0 1-.57-.12 1.489 1.489 0 0 1-.81-1.96 1.5 1.5 0 0 1 2.77 1.15ZM702 310a1.5 1.5 0 0 1-3 0v-.06a1.5 1.5 0 0 1 3-.01v.07Z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
    return (
        <DialogBox
            isnotTitle
            maxWidth={"xs"}
            handleClose={state?.version_priority === "High" ? false : () => handleVersionAlert("")}
            open={state?.is_version_alert_open}
            height={"330px"}
            borderRadius={"8px"}
            component={
                <Box p={2} textAlign={"center"}>
                    <Box>
                        <SvgComponent />
                    </Box>
                    <Box>
                        <Typography sx={{ fontFamily: FontFamilySwitch().bold, fontSize: "1.125rem", color: "#091B29" }}>{"Update Available"}</Typography>
                        <Typography sx={{ fontFamily: FontFamilySwitch().semiBold, fontSize: "0.875rem", color: "#4E5A6B", marginTop: "6px" }}>{`${"A newer version is available"} - v${state?.new_version}`}</Typography>
                    </Box>
                    <Box display={"flex"} justifyContent={"space-around"} alignItems={"center"} marginTop={"8px"}>
                        {state?.version_priority !== "High" &&
                            <Button
                                fullWidth
                                variant="outlined"
                                sx={{
                                    marginInlineEnd: "6px",
                                    '&:hover': {
                                        backgroundColor: 'transparent', // Remove the background color on hover
                                    },
                                    '&:focus': {
                                        backgroundColor: 'transparent', // Remove the background color when focused
                                    },
                                }}
                                onClick={() => {
                                    handleVersionAlert("")
                                }}>
                                Remind Me Later
                            </Button>
                        }

                        <Button
                            fullWidth
                            variant="contained"
                            sx={{
                                marginInlineStart: "6px",
                            }}
                            onClick={() => {
                                refreshCacheAndReload(state?.new_version)
                                handleVersionAlert("")
                            }}>
                            Update Now
                        </Button>


                    </Box>

                </Box>
            } />

    )
}