import { borderRadius } from "../../utils";
import { FontFamilySwitch } from "../../utils/font";

export const ShortlistCardStyle = {
    parentBox: {
        padding: "16px 0px",
        borderRadius: "0px 4px 4px 4px",
        overflow: "hidden",
    },
    infiniteScroll: {
        '::-webkit-scrollbar': {
            display: 'none'
        }
    },
    imgBox: {
        borderRadius: borderRadius,
        width: "48px",
        height: "48px"

    },
    dot: {
        width: "6px",
        height: "6px",
        margin: "0px 7px",
        backgroundColor: "primary.light1",
        borderRadius: "50%"
    },
    unitChip: {
        borderRadius: "12px",
        fontSize: "0.625rem",
        fontFamily: FontFamilySwitch().regular,
        color: "primary.contrastText",
        padding: "3px 6px",
        backgroundColor: "#30C4AB",
        width:"65px"
    },
    viewBtn: {
        padding: "12px",
        border: "1px solid #E4E8EE",
        borderRadius: "311px",
        "& p": {
            color: "primary.main",
            fontSize:  "0.75rem",
            fontFamily: FontFamilySwitch().semiBold
        }
    },
    shortlistDetails: {
        width: "auto",
        margin: "0 37px 0 10px"
    },
    amnt: {
        margin: "0 40px",
        fontSize:  "0.875rem",
        fontFamily: FontFamilySwitch().semiBold,

        color: "primary.light1",
    },
    unitDetails: {
        color: "primary.light1",
        fontSize:  "0.75rem",
        fontFamily: FontFamilySwitch().regular

    },
    code: {
        color: "primary.light1",
        fontSize:  "0.875rem",
        fontFamily: FontFamilySwitch().semiBold

    },
    stackChild: {
        marginTop: "6px",
        alignItems: "center"
    },
    stackParent: {
        alignItems: "center",
        justifyContent: "space-between",
        padding: "16px 0",
        borderBottom: "1px solid #E4E8EE"
    }
}