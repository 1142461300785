import { borderRadius } from "../../utils";
import { FontFamilySwitch } from "../../utils/font";

export const SlotsCardStyle = {
  maincard: {
    height: "62px",
    color: "text.default",
    border: "1px solid #E4E8EE",
    backgroundColor: "#FFFFFF",
    borderRadius: borderRadius,
    padding: "12px 6px",
    display: "grid",
    justifyContent: "center",
    width: "auto",
    position: "relative"
  },
  maincardselected: {
    height: "62px",
    color: "text.default",
    border: "1px solid #1F74EA",
    backgroundColor: "#5078E10D",
    borderRadius: borderRadius,
    padding: "12px 6px",
    display: "grid",
    justifyContent: "center",
    width: "auto",
    position: "relative"

  },
  bookedcard: {
    backgroundColor: "#F2F4F7",
    height: "62px",
    color: "text.default",
    border: "1px solid #E4E8EE",
    borderRadius: borderRadius,
    padding: "12px",
    display: "grid",
    justifyContent: "center",
  },
  underMaintenanceCard: {
    backgroundColor: "#FFF4EB",
    height: "62px",
    color: "text.default",
    border: "1px solid #FF9340",
    borderRadius: borderRadius,
    padding: "12px",
    display: "grid",
    justifyContent: "center",
  },
  text: {
    fontFamily: FontFamilySwitch().medium,
    fontSize: {
      xs: "12px",
      sm: "14px",
      md: "14px",
      lg: "14px",
    },
    color: "#4E5A6B",
    textAlign: "center",
    margin: "auto"
  },
  textselected: {
    fontFamily: FontFamilySwitch().medium,
    fontSize: {
      xs: "12px",
      sm: "14px",
      md: "14px",
      lg: "14px",
    },
    color: "#1F74EA",
    textAlign: "center",
    margin: "auto"
  },
  textbooked: {
    fontFamily: FontFamilySwitch().medium,
    fontSize: {
      xs: "12px",
      sm: "14px",
      md: "14px",
      lg: "14px",
    },
    color: "#98A0AC",
    textAlign: "center"
  },
  textUnderMaintenance: {
    fontFamily: FontFamilySwitch().medium,
    fontSize: {
      xs: "12px",
      sm: "14px",
      md: "14px",
      lg: "14px",
    },
    color: "#FF9340",
    textAlign: "center"
  },
  texthour: {
    color: "#98A0AC",
    fontSize: "9px !important",
    fontFamily: FontFamilySwitch().regular,

  },
  texthourselected: {
    color: "#5078E1",
    fontSize: "0.9rem !important",
    fontFamily: FontFamilySwitch().regular,

  },
  textperson: {
    color: "#98A0AC",
    fontSize: {
      xs: "11px",
      sm: "12px",
      md: "12px",
      lg: "12px",
    },
    fontFamily: FontFamilySwitch().medium,
    textAlign: "center"
  },
  textpersonselected: {
    color: "#5078E1",
    fontSize: {
      xs: "11px",
      sm: "12px",
      md: "12px",
      lg: "12px",
    },
    fontFamily: FontFamilySwitch().medium,
    textAlign: "center"
  },
  textbooking: {
    color: "#98A0AC",
    fontSize: {
      xs: "11px",
      sm: "12px",
      md: "12px",
      lg: "12px",
    },
    fontFamily: FontFamilySwitch().medium,
    display: "flex",
    justifyContent: "center",
  },
  underMaintenancetext: {
    color: "#FF9340",
    fontSize: {
      xs: "11px",
      sm: "12px",
      md: "12px",
      lg: "12px",
    },
    fontFamily: FontFamilySwitch().medium,
    display: "flex",
    justifyContent: "center",
  },
  textslot: {
    // display: "flex",
    // alignItems: "center",
    textAlign: "center"
  },
  doticon: {
    color: "#E4E8EE",
    height: "4px",
    width: "4px",
    marginLeft: "4px",
    marginRight: "4px",
  },
  doticonselected: {
    color: "#5078E1",
    height: "4px",
    width: "4px",
    marginLeft: "4px",
    marginRight: "4px",
  },
  peakHourIcon: {
    position: "absolute",
    top: "-8px",
    left: "0px",
    right: "0px",
    display: "flex",
    justifyContent: "center"
  },
  overBookingIcon: {
    position: "absolute",
    right: "1px",
    top: "2px",
    zIndex: 100,
    fontSize: "0.75rem",
    color: "#98A0AC",
    fontFamily: FontFamilySwitch().regular
  }
};
