import { borderRadius, borderRadiusAlignments, remCalc } from "../../utils";
import { FontFamilySwitch } from "../../utils/font";

export const SearchResultsCardStyle = {
  main: {
    height: "287px",
    width: "100%",
    borderRadius: borderRadiusAlignments?.borderEight,
    opacity: 1,
    boxShadow: "none",
    border: "1px solid #CED3DD",
    position: "relative"
  },
  maintext: {
    color: "text.primary",
    fontSize: remCalc(16),
    height: "24px",
    fontFamily: FontFamilySwitch().semiBold,
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    " -webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
    maxWidth: "100%",
    whiteSpace: "break-spaces",
    marginBottom: "8px"
  },
  subText: {
    color: "primary.light1",
    fontSize: remCalc(12),
    fontFamily: FontFamilySwitch().regular,
  },
  chip: {
    position: "absolute",
    // backgroundColor: "hsla(170, 61%, 48%, 1)",
    backgroundColor:"primary.contrastText",
    color: "text.primary",
    fontSize: remCalc(12),
    fontFamily: FontFamilySwitch().semiBold,
    // height: "28px",
    // maxHeight: "28px",
    // width: "152px",
    right: 0,
    marginTop: "10px",
    marginRight: "20px",
    zIndex: 2,
    padding:"3px 4px",
    borderRadius:borderRadius,
    border:"0.5px solid",
    borderColor:"border.primary"
  },
  button1: {
    backgroundColor: "#F2F4F7",
    height: "40px",
    // borderRadius: "16px",
    borderRadius: borderRadius,
    width: "100%",
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().medium,
    fontSize: remCalc(12),
    border: "none",
    ml: "6px",
    mr: "4px",
    "&:hover": {
      border: "none",
      color: "#1F74EA",
      backgroundColor: "#F1F7FF",
    },
  },
  button2: {
    height: "40px",
    // borderRadius: "16px ",
    borderRadius: borderRadius,
    width: "100%",
    color: "#1F74EA",
    fontFamily: FontFamilySwitch().medium,
    fontSize: remCalc(12),
    border: "1px solid #1F74EA",
    ml: "6px",
    mr: "4px",
    // width:"100%"
  },
  cardaction: {
    justifyContent: "space-around",
    padding: "4px 4px 16px",
    display: "flex"
  },
  cardcontent: {
    padding: "12px 16px 8px !important",
  },
  image: {
    height: "140px",
    borderRadius: borderRadius,
    width: "100%",
    display: "grid",
    alignItems: "center"

  },
  avatarBox: {
    display: "flex",
    justifyContent: "center",
  },
  avatar: {
    display: "flex",
    justifyContent: "center",
    marginTop: "40px"
  },
  topBox: {
    padding: "16px 16px 0px"
  }
};
