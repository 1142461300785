import React from "react";
import { Tabs, Tab, Hidden } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  CommercialIcon,
  FacilitiesIcon,
  ResidentialIcon,
} from "../../assets/components";
import { tabStyle } from "./style";
import { FontFamilySwitch } from "../../utils/font";
import { remCalc } from "../../utils";
import { config } from "../../config";

export const TabComponent = ({
  handleChange = () => false,
  onClick = () => false,
  value = "",
  t = () => false,
}) => {
  let hostName = window.location.origin;
  let host_arr = [
    config?.listings_url,
    config?.realitygoto_url,
    config?.amenitiesgoto_url,
  ];
  hostName = host_arr?.includes(hostName) ? hostName : config?.listings_url;
  const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))(({ theme }) => ({
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      bottom: "15px",
      [theme.breakpoints.down("md")]: {
        bottom: "0px",
        display: "block !important",
      },
      [theme.breakpoints.down("sm")]: {
        display: "flex !important",
        flexDirection: "column !important",
      },
    },
    "& .MuiTabs-flexContainer": {
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column !important",
        "& .MuiButtonBase-root": {
          display: "flex",
          justifyContent: "start",
          minHeight: "54px !important",
          padding: "0px !important",
        },
      },
    },

    "& .MuiTabs-indicatorSpan": {
      maxWidth: 35,
      width: "100%",
      marginInlineStart: "30px!important",
      backgroundColor: theme.palette.primary.main,
    },
    "& .MuiButtonBase-root.MuiTabScrollButton-root": {
      display: "none",
      fontFamily: FontFamilySwitch().medium,
    },
    "&.MuiTabs-root": {
      display: "flex",
      minHeight: "48px",
      fontFamily: FontFamilySwitch().medium,
      // overflow:"hidden",
      [theme.breakpoints.down("lg")]: {
        width: "340px",
        overflow: "hidden",
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    // MuiButtonBase-root
  }));

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      fontSize: "1rem",
      marginRight: 1,
      fontWeight: 600,
      fontFamily: FontFamilySwitch().semiBold,
      color: theme.palette.text.tertiary,
      [theme.breakpoints.down("md")]: {
        minWidth: "75px",
      },
      "&.Mui-selected": {
        color: "#091B29",
        // color: theme.palette.text.secondary,
        fontSize: "1rem",
        fontWeight: 600,
        fontFamily: FontFamilySwitch().semiBold,
      },
      // [theme.breakpoints.down('sm')]: {
      //   minWidth: '75px',

      // },

      "&.Mui-focusVisible": {
        backgroundColor: "rgba(100, 95, 228, 0.32)",
      },
      "& .MuiTab-iconWrapper": {
        marginInlineEnd: "8px",
      },
    })
  );
  const StyledTabMob = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      marginRight: 1,
      fontSize: remCalc(16),
      fontFamily: FontFamilySwitch().semiBold,
      color: theme.palette.text.tertiary,
      [theme.breakpoints.down("md")]: {
        minWidth: "75px",
      },
      "&.Mui-selected": {
        color: "#091B29",
        // color: theme.palette.text.secondary,
        fontSize: remCalc(16),
        fontFamily: FontFamilySwitch().semiBold,
      },
      // [theme.breakpoints.down('sm')]: {
      //   minWidth: '75px',

      // },

      "&.Mui-focusVisible": {
        backgroundColor: "rgba(100, 95, 228, 0.32)",
      },
      "& .MuiTab-iconWrapper": {
        marginInlineEnd: "8px",
      },
    })
  );
  const amenitiesUrl = config.amenitiesgoto_url;
  const publicListingsUrl = config.listings_url;
  const realityUrl = config.realitygoto_url;

  const navBarConfiguration = {
    [amenitiesUrl]: (
      <>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="secondary tabs example"
          sx={tabStyle.tab}
          variant="scrollable"
        ></StyledTabs>
      </>
    ),
    [publicListingsUrl]: (
      <>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="secondary tabs example"
          sx={tabStyle.tab}
          variant="scrollable"
        >
          <StyledTab
            onClick={() => onClick(0)}
            icon={
              <ResidentialIcon
                rootStyle={{
                  color: value === 0 ? "primary.main" : "text.tertiary",
                }}
              />
            }
            iconPosition="start"
            label={t("Residential")}
          />
          <StyledTab
            onClick={() => onClick(1)}
            icon={
              <CommercialIcon
                rootStyle={{
                  color: value === 1 ? "primary.main" : "text.tertiary",
                }}
              />
            }
            iconPosition="start"
            label={t("Commercial")}
          />

          {/* <StyledTab onClick={() => onClick(3)} icon={<CommercialIcon rootStyle={{ color: value === 3 ? "primary.main" : 'text.tertiary' }} />} iconPosition="start" label="Facilities" /> */}
          {/* <StyledTab onClick={() => onClick(2)} icon={<FacilitiesIcon rootStyle={{ color: value === 2 ? "primary.main" : 'text.tertiary' }} />} iconPosition="start" label="Stays" /> */}
          {/* <StyledTab onClick={() => onClick(2)} icon={<FacilitiesIcon rootStyle={{ color: value === 2 ? "primary.main" : 'text.tertiary' }} />} iconPosition="start" label={t("Amenities")} /> */}
        </StyledTabs>
      </>
    ),
    [realityUrl]: (
      <>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="secondary tabs example"
          sx={tabStyle.tab}
          variant="scrollable"
        >
          <StyledTab
            onClick={() => onClick(0)}
            icon={
              <ResidentialIcon
                rootStyle={{
                  color: value === 0 ? "primary.main" : "text.tertiary",
                }}
              />
            }
            iconPosition="start"
            label={t("Residential")}
          />
          <StyledTab
            onClick={() => onClick(1)}
            icon={
              <CommercialIcon
                rootStyle={{
                  color: value === 1 ? "primary.main" : "text.tertiary",
                }}
              />
            }
            iconPosition="start"
            label={t("Commercial")}
          />

          {/* <StyledTab onClick={() => onClick(3)} icon={<CommercialIcon rootStyle={{ color: value === 3 ? "primary.main" : 'text.tertiary' }} />} iconPosition="start" label="Facilities" /> */}
          {/* <StyledTab onClick={() => onClick(2)} icon={<FacilitiesIcon rootStyle={{ color: value === 2 ? "primary.main" : 'text.tertiary' }} />} iconPosition="start" label="Stays" /> */}
          {/* <StyledTab onClick={() => onClick(2)} icon={<FacilitiesIcon rootStyle={{ color: value === 2 ? "primary.main" : 'text.tertiary' }} />} iconPosition="start" label={t("Amenities")} /> */}
        </StyledTabs>
      </>
    ),
  };
  const navBarMobConfiguration = {
    [amenitiesUrl]: (
      <>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="secondary tabs example"
          sx={tabStyle.tab}
          variant="scrollable"
        ></StyledTabs>
      </>
    ),
    [publicListingsUrl]: (
      <>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="secondary tabs example"
          sx={tabStyle.tab}
          variant="scrollable"
        >
          <StyledTabMob
            onClick={() => onClick(0)}
            icon={
              <ResidentialIcon
                rootStyle={{
                  color: value === 0 ? "primary.main" : "text.tertiary",
                }}
              />
            }
            iconPosition="start"
            label={t("Residential")}
          />
          <StyledTabMob
            onClick={() => onClick(1)}
            icon={
              <CommercialIcon
                rootStyle={{
                  color: value === 1 ? "primary.main" : "text.tertiary",
                }}
              />
            }
            iconPosition="start"
            label={t("Commercial")}
          />

          {/* <StyledTabMob onClick={() => onClick(3)} icon={<CommercialIcon rootStyle={{ color: value === 3 ? "primary.main" : 'text.tertiary' }} />} iconPosition="start" label="Facilities" /> */}
          {/* <StyledTabMob onClick={() => onClick(2)} icon={<FacilitiesIcon rootStyle={{ color: value === 2 ? "primary.main" : 'text.tertiary' }} />} iconPosition="start" label="Stays" /> */}
          {/* <StyledTabMob
            onClick={() => onClick(2)}
            icon={
              <FacilitiesIcon
                rootStyle={{
                  color: value === 2 ? "primary.main" : "text.tertiary",
                }}
              />
            }
            iconPosition="start"
            label={t("Amenities")}
          /> */}
        </StyledTabs>
      </>
    ),
    [realityUrl]: (
      <>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="secondary tabs example"
          sx={tabStyle.tab}
          variant="scrollable"
        >
          <StyledTabMob
            onClick={() => onClick(0)}
            icon={
              <ResidentialIcon
                rootStyle={{
                  color: value === 0 ? "primary.main" : "text.tertiary",
                }}
              />
            }
            iconPosition="start"
            label={t("Residential")}
          />
          <StyledTabMob
            onClick={() => onClick(1)}
            icon={
              <CommercialIcon
                rootStyle={{
                  color: value === 1 ? "primary.main" : "text.tertiary",
                }}
              />
            }
            iconPosition="start"
            label={t("Commercial")}
          />

          {/* <StyledTabMob onClick={() => onClick(3)} icon={<CommercialIcon rootStyle={{ color: value === 3 ? "primary.main" : 'text.tertiary' }} />} iconPosition="start" label="Facilities" /> */}
          {/* <StyledTabMob onClick={() => onClick(2)} icon={<FacilitiesIcon rootStyle={{ color: value === 2 ? "primary.main" : 'text.tertiary' }} />} iconPosition="start" label="Stays" /> */}
          {/* <StyledTabMob onClick={() => onClick(2)} icon={<FacilitiesIcon rootStyle={{ color: value === 2 ? "primary.main" : 'text.tertiary' }} />} iconPosition="start" label={t("Amenities")} /> */}
        </StyledTabs>
      </>
    ),
  };

  return (
    <>
      {/* web */}
      <Hidden mdDown>{navBarConfiguration[hostName]}</Hidden>

      {/* mobile */}
      <Hidden mdUp>{navBarMobConfiguration[hostName]}</Hidden>
    </>
  );
};
