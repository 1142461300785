import Select, { components } from 'react-select';
import React, { useEffect, useRef, useState } from 'react';
import { selectBarStyle, otherStyles } from './style';
import { Box, Checkbox } from '@mui/material';
import { CustomButton } from '../Button';
import { FontFamilySwitch } from '../../utils/font';
import { AsyncPaginate } from 'react-select-async-paginate';

export const ReactSelect = ({
  options = [],
  type = '',
  isMulti = true,
  closeMenuOnSelect = false,
  hideSelectedOptions = false,
  allowSelectAll = false,
  rootStyle = {},
  handleChange = () => false,
  onUnitApply = () => false,
  placeholder = '',
  selectedRef,
  value,
  valueText,
  isClearable = false,
  loadOptions = () => false,
  loading = false,
  debounceTimeout,
  reduceOptions,
  isPaginate,
  key,
  parentID,
  menuPlacement,
  ...rest

}) => {
  const [state, setState] = useState(false);
  const selectRef = useRef(null);

  const handleToggle = () => {
    setState(!state);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target)
      ) {
        setState(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('touchstart', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('touchstart', handleOutsideClick);
    };
  }, []);

  const ValueContainer = ({ children, ...props }) => {
    let [values] = children;

    if (Array.isArray(values)) {
      const val = (i) => values[i].props.children;
      const { length } = values;
      const otherCount = length - 1;
      if (otherCount >= 1) values = `${val(0)},+${otherCount} more`;
      else values = `${val(0)}`;
    } else {
      values = valueText ?? 'All Unit';
    }

    return (
      components.ValueContainer && (
        <components.ValueContainer {...props}>
          <div sx={otherStyles.valueBox} onClick={handleToggle} onTouchStart={handleToggle}>
            {values}
          </div>
        </components.ValueContainer>
      )
    );
  };
  const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    onChange,
    data,
    ...rest
  }) => {
    const [isActive, setIsActive] = React.useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);


    // styles
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
      alignItems: "center",
      backgroundColor: bg,
      display: "flex ",
      color: "#4E5A6B",
      fontSize: "14px",
      fontFamily: FontFamilySwitch().medium
    };

    // prop assignment
    const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style
    };


    return (
      <>
        <components.Option
          {...rest}
          isDisabled={isDisabled}
          isFocused={isFocused}
          isSelected={isSelected}
          getStyles={getStyles}
          innerProps={props}
        >
          <Checkbox checked={isSelected}
          // onChange={(e) => handleChange(data?.value, e.target.checked)} 
          />
          {children}
        </components.Option>
      </>

    );
  };


  const Menu = (props) => {
    return (
      <>
        <components.Menu {...props}>
          <div>
            {props.children}
            <Box sx={otherStyles.buttonBox}>
              <CustomButton
                label="Apply"
                variant="contained"
                color="secondary"
                rootStyle={otherStyles.button}
                onClick={() => {
                  onUnitApply();
                  setState(false);
                }}
              />
            </Box>
          </div>
        </components.Menu>
      </>
    );
  };

  return (
    <div ref={selectRef}>
      {isPaginate ?
        <AsyncPaginate
          key={key}
          menuIsOpen={state}
          isClearable={isClearable}
          isSearchable={false}
          components={{
            IndicatorSeparator: () => false,
            ValueContainer,
            Option: InputOption,
            Menu,
          }}
          value={value}
          placeholder={placeholder}
          loadOptions={loadOptions}
          onChange={(e, v) => handleChange(e, v)}
          options={options}
          isLoading={loading}
          defaultOptions={options}
          styles={{ ...selectBarStyle, ...rootStyle }}
          isMulti={isMulti}
          debounceTimeout={debounceTimeout}
          reduceOptions={reduceOptions}
          menuPortalTarget={document.getElementById(parentID)}
          closeMenuOnSelect={closeMenuOnSelect}
          ref={selectedRef}
          hideSelectedOptions={hideSelectedOptions}
          allowSelectAll={allowSelectAll}
          onMenuOpen={() => setState(true)}
          onMenuClose={() => setState(false)}
          menuPlacement="auto"
        />
        : <Select
          menuIsOpen={state}
          value={value}
          options={options}
          styles={{ ...selectBarStyle, ...rootStyle }}
          isMulti={isMulti}
          components={{
            IndicatorSeparator: () => false,
            ValueContainer,
            Option: InputOption,
            Menu,
          }}
          loadOptions={loadOptions}
          onChange={(e, v) => handleChange(e, v)}
          isSearchable={false}
          isClearable={isClearable}
          placeholder={placeholder}
          closeMenuOnSelect={closeMenuOnSelect}
          ref={selectedRef}
          hideSelectedOptions={hideSelectedOptions}
          allowSelectAll={allowSelectAll}
          onMenuOpen={() => setState(true)}
          onMenuClose={() => setState(false)}
          menuPlacement="auto"
          {...rest}
        />}
    </div>
  );
};
