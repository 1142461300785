/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
import { ArrowDropDown } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  AppBar,
  Avatar,
  Box,
  Chip,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Popover,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";

import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AmenetiesGotoIcon,
  Hamburger,
  KeysIcon,
  ListingsLogo,
  Logo,
} from "../../../assets/components";
import { config } from "../../../config";
import { AlertContext, AuthContext } from "../../../contexts";
import {
  useAreaMetricMutation,
  useCountryMasterMutation,
  useCurrencyMasterMutation,
  useGetOldUserManualMutation,
  usePutUserMutation,
} from "../../../redux/services/users";
import {
  useLoginMutation,
  useLogoutMutation,
  useDeleteMutation,
} from "../../../redux/services";
import { SignIn } from "../../../screens";
import { AlertProps, LocalStorageKeys } from "../../../utils";
import { getCoords } from "../../../utils/common";
import { CustomButton } from "../../Button";
import { TabComponent } from "../../Icontab";
import { CountryCard } from "../../countryCard";
import { DialogBox } from "../../dialogBox";
import EnquiryForm from "../../propertyEnquiry";
import { DefautTab } from "../../tab";
import { topBarStyles } from "./style";
import { LanguageDetails, logout, userSnippet, userSnippetMob } from "./utils";
import { jwtDecode } from "jwt-decode";

export const TopNavBar = ({ ...props }) => {
  const { t = () => false } = props;
  // Get the current URL of the page
  const client = localStorage.getItem(LocalStorageKeys.client);

  const search = useLocation().search;
  const queryParams = new URLSearchParams(search);
  let hostName = window.location.origin;
  let host_arr = [
    config?.listings_url,
    config?.realitygoto_url,
    config?.amenitiesgoto_url,
  ];
  hostName = host_arr?.includes(hostName) ? hostName : config?.listings_url;
  const amenitiesUrl = config.amenitiesgoto_url;
  const publicListingsUrl = config.listings_url;
  const realityUrl = config.realitygoto_url;
  // Step 2: Create an object to store the parameters
  const queryObject = {};

  queryParams?.forEach((value, key) => {
    // Check if the key is latitude or longitude and parse the value to a number
    if (key === "latitude" || key === "longitude") {
      queryObject[key] = parseFloat(value);
    } else {
      queryObject[key] = value;
    }
  });

  // Now, the query parameters are stored in the queryObject object

  // Access specific parameters
  const device = queryObject?.device;

  // Now, the query parameters are stored in the queryObject object

  const selectCode = JSON.parse(
    localStorage.getItem(LocalStorageKeys.selected_country_detail)
  );
  const auth = React.useContext(AuthContext);
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const navigate = useNavigate();
  const [modal, setModal] = React.useState(false);
  const [countryModal, setCountryModal] = React.useState(false);
  const [languageModal, setLanguageModal] = React.useState(false);
  const [signInModal, setSignInModal] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [modelOpen, setmodel] = React.useState(null);
  const openModel = Boolean(modelOpen);
  const [value, setValue] = React.useState(0);
  const [toggle, setToggle] = React.useState(0);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const history = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const getselectedlag = props?.i18n?.language;
  const [selectedLanguage, setSelectedLanguage] = React.useState(
    LanguageDetails?.find((val) => val?.value === getselectedlag) ??
      LanguageDetails[0]
  );
  const [selectedCountry, setSelectedCountry] = React.useState({ 
    label: "All Countries",
    code: "All",
    url: "/images/worldcon.svg",});
  const [CountryDetails, setCountryDetails] = React.useState([]);
  const [currency, setCurrency] = React.useState([]);
  const [selectedCurrency, setSelectedCurrency] = React.useState();
  const alert = React.useContext(AlertContext);
  const [updateUserManual] = usePutUserMutation();
  const [countryMasterApi] = useCountryMasterMutation();
  const [currencyMasterApi] = useCurrencyMasterMutation();
  const [showUserManual] = useGetOldUserManualMutation();
  const [areaMetric] = useAreaMetricMutation();
  const [area, setArea] = React.useState([]);
  const [selectedMetric, setSelectedMetric] = React.useState();
  const [anchor, setAnchor] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open1 = Boolean(anchorEl1);
  const [loading, setLoading] = React.useState(false);
  const [login] = useLoginMutation();
  const [logOut] = useLogoutMutation();
  const [deleteUser] = useDeleteMutation();

  const initialState = {
    emailId: "",
    password: "",
    showPassword: false,
    error: {
      emailId: "",
      password: "",
    },
  };
  const [data, setData] = React.useState({ ...initialState });

  const updateData = (key, value) => {
    let error = data.error;
    error[key] = "";
    setData({ ...data, [key]: value, error });
  };
  // form validations
  const validate = () => {
    let isValid = true;
    const error = data?.error;
    if (data?.emailId.length === 0) {
      isValid = false;
      error.emailId = "Email Id is Required";
    }
    if (data?.password?.length === 0) {
      isValid = false;
      error.password = "Password is Required";
    }
    setData({ ...data, error });
    return isValid;
  };
  const validateUser = () => {
    let isValid = true;
    if (selectedCountry?.id?.length === 0 || !selectedCountry) {
      isValid = false;
    }
    if (selectedCurrency?.currency_id?.length === 0 || !selectedCurrency) {
      isValid = false;
    }
    return isValid;
  };
  // updateUser
  const updateUserApi = (data) => {
    if (validateUser()) {
      const payload = {
        country: selectedCountry?.id,
        currency: selectedCurrency?.currency_id,
        area_metric: data?.id ?? selectedMetric?.id,
        user_id: localStorage.getItem(LocalStorageKeys.user_Id),
      };
      updateUserManual(payload).catch((error) => {
        console.log(error);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went wrong"),
        });
      });
    }
  };

  React.useEffect(() => {
    if (authToken) {
      const token = jwtDecode(authToken)?.id;
      localStorage.setItem(LocalStorageKeys.user_Id, token);
      updateUserApi();
    }
    // eslint-disable-next-line
  }, [selectedCountry, selectedCurrency]);
  // ShowUser
  const showUserApi = (user_id) => {
    showUserManual(user_id)
      .then((response) => {
        setSelectedCurrency({
          currency_code:
            response?.data?.response?.currency_code ??
            selectedCurrency?.currency_code,
          symbol:
            response?.data?.response?.currency_symbol ??
            selectedCurrency?.currency_symbol,
          currency_id:
            response?.data?.response?.currency_id ??
            selectedCurrency?.currency_id,
        });
        localStorage.setItem(
          LocalStorageKeys.selected_currency,
          JSON.stringify(response?.data?.response ?? selectedCurrency)
        );
        setSelectedCountry({
          label:
            response?.data?.response?.country_name ?? selectedCountry?.label,
          id: response?.data?.response?.id ?? selectedCountry?.id,
          url: response?.data?.response?.url ?? selectedCountry?.url,
        });
        setSelectedMetric({
          name:
            response?.data?.response?.area_metric_name ?? selectedMetric?.name,
        });
      })

      .catch((error) => {
        console.log(error);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went wrong"),
        });
      });
  };

  const handleClickShowPassword = () => {
    updateData("showPassword", !data?.showPassword);
  };

  const Login = async () => {
    if (validate()) {
      setLoading(true);
      try {
        const log = await login({
          email: data.emailId,
          password: data.password,
        });
        if (log?.data?.statusCode === 200) {
          localStorage.setItem(LocalStorageKeys.username, log?.data?.username);
          localStorage.setItem(LocalStorageKeys.authToken, log?.data?.token);
          const expirationTime = new Date().getTime() + 23 * 60 * 60 * 1000;
          localStorage.setItem("expirationTime", expirationTime);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: "User Logged In successfully",
          });
          handleSignInModalClose();
          // history(auth?.clientRoutes?.listing)
          setLoading(false);
          showUserApi({ user_id: log?.data?.id });
        } else {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t(log?.error?.data?.message),
          });
          setLoading(false);
        }
      } catch (error) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: error?.message ?? "Incorrect username or password",
        });
        setLoading(false);
      }
    } else {
      return false;
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // language translate
  const updateLanguage = (data) => {
    if (data?.value === "ar") {
      document.getElementsByTagName("HTML")[0].dir = "rtl";
    } else {
      document.getElementsByTagName("HTML")[0].dir = "ltr";
    }
    setAnchor(!anchor);
    setAnchorEl1(null);
    setSelectedLanguage(data);
    saveLanguage(data);
  };
  //delete account
  const deleteUserAccount = async () => {
    try {
      // Sign out the current user
      const id = localStorage.getItem(LocalStorageKeys.user_Id);
      await logOut({ user_id: id });

      // soft delete userr
      const currentUser = localStorage.getItem(LocalStorageKeys.user_Id);

      await deleteUser({ user_id: currentUser });

      const payload = {
        user_id: currentUser,
        is_active: false,
      };
      updateUserManual(payload).catch((error) => {
        console.log(error);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went wrong"),
        });
      });

      localStorage.clear();

      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.success,
        msg: t("Account Deleted successfully"),
      });
      localStorage.setItem(
        LocalStorageKeys.selected_currency,
        JSON.stringify(selectedCurrency)
      );
      setmodel(false);
      setDeleteModal(false);
      console.log("User deleted successfully.");
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  //updateCountry
  const updateCountry = (data) => {
    localStorage.setItem(LocalStorageKeys.selected_country, data?.code);
    localStorage.setItem(
      LocalStorageKeys.selected_country_detail,
      JSON.stringify(data)
    );
    setSelectedCountry(data);
    setCountryModal(false);
    setAnchorEl1(null);
    setAnchor(!anchor);
  };

  // updateCurrency
  const updateCurrency = (data) => {
    localStorage.setItem(
      LocalStorageKeys.selected_currency,
      JSON.stringify(data)
    );
    setSelectedCurrency(data);
    auth?.updateAuth("currency", data);
    setAnchorEl1(null);
    setAnchor(!anchor);
  };
  // updateMetric
  const updateMetric = (data) => {
    localStorage.setItem(
      LocalStorageKeys.selected_metric,
      JSON.stringify(data)
    );
    setSelectedMetric(data);
    auth?.updateAuth("metric", data);
    setAnchorEl1(null);
    setAnchor(!anchor);
    if (authToken) {
      updateUserApi(data);
    }
  };
  //saveLanguage
  const saveLanguage = (LanguageDetails) => {
    props?.i18n.changeLanguage(LanguageDetails?.value);
    setLanguageModal(false);
    auth?.setAuth({ ...auth });
  };

  //getcountryMaster
  const getCountry = (locationdata) => {
    const payload = {
      offset: 0,
      limit: 10,
    };
    countryMasterApi(payload)
      .then((response) => {
        let object = {
          label: "All Countries",
          code: "All",
          url: "/images/worldcon.svg",
        };
        let result = [];

        // response?.data?.response?.country?.map((item) => {
        //   return {
        //     id: item?.id,
        //     code: item?.country_code,
        //     label: item?.country_name,
        //     url: item?.url ?? null,
        //     center: item?.center,
        //   };
        // });
        result.unshift(object);

        setCountryDetails(result);
        result?.map((e) => {
          if (locationdata?.code === e?.code) {
            setSelectedCountry({
              code: locationdata?.code,
              label: locationdata?.country_name,
              url: e?.url ?? null,
              ...e,
            });
          }
        });
      })
      .catch((error) => {
        console.log(error);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went wrong"),
        });
      });
  };

  //getCurrencyMaster
  const getCurrencyMaster = () => {
    const payload = {
      offset: 0,
      limit: 10,
    };
    currencyMasterApi(payload)
      .then((response) => {
        let temp_data = response?.data?.response?.currency;
        setCurrency({
          data: temp_data?.map((val) => {
            return {
              amount: val?.amount,
              currency_code: val?.currency_code,
              currency_id: val?.currency_id,
              symbol: val?.symbol,
            };
          }),
        });
        const currency = localStorage.getItem(
          LocalStorageKeys.selected_currency
        )
          ? JSON.parse(localStorage.getItem(LocalStorageKeys.selected_currency))
          : null;

        if (currency && Object.keys(currency)?.length > 0) {
          setSelectedCurrency(currency);
        } else {
          setSelectedCurrency(temp_data?.[1]);
          localStorage.setItem(
            LocalStorageKeys.selected_currency,
            JSON.stringify(temp_data?.[1])
          );
        }
      })
      .catch((error) => {
        console.log(error);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went wrong"),
        });
      });
  };

  // area metrric function
  const areaMetricFunction = () => {
    areaMetric({
      offset: 0,
      limit: 10,
    })
      .then((res) => {
        let temp_data = res?.data?.response?.data;
        setArea({
          data: temp_data?.map((val) => {
            return {
              id: val?.id,
              name: val?.name,
            };
          }),
        });
        const metric = JSON.parse(
          localStorage.getItem(LocalStorageKeys.selected_metric)
        );
        if (
          auth?.metric?.name?.length > 0 ||
          (metric && Object.keys(metric)?.length > 0)
        ) {
          setSelectedMetric({
            id: auth?.metric?.id ?? metric?.id,
            name: auth?.metric?.name ?? metric?.name,
          });
        } else {
          setSelectedMetric(temp_data?.[2]);
          localStorage.setItem(
            LocalStorageKeys.selected_metric,
            JSON.stringify(temp_data?.[2])
          );
          auth?.updateAuth("metric", temp_data?.[2]);
        }
        // setSelectedMetric(selectedMetric ?? temp_data?.[0])
      })
      .catch((error) => {
        console.log(error);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went wrong"),
        });
      });
  };

  React.useEffect(() => {
    areaMetricFunction();
    getCurrencyMaster();
    if (selectCode) {
      setSelectedCountry({
        code: selectCode?.code,
        label: selectCode?.label,
        url: selectCode?.url ?? null,
      });
    } else {
      getCurrentLocation();
    }
    //eslint-disable-next-line
  }, []);
  React.useEffect(() => {
    setToggle(props?.tab);
    //eslint-disable-next-line
  }, [props?.tab]);

  // Handle Tab Change
  const handleToggleChange = (event, newValue) => {
    if (newValue === 0 || event === 0) {
      history(auth?.clientRoutes?.listing);
    } else if (newValue === 1 || event === 1) {
      history(auth?.clientRoutes?.commercial);
    }
    // else if (newValue === 2 || event === 2) {
    //   history(auth?.clientRoutes?.staysScreen)
    //   return false
    // }
    else if (newValue === 2 || event === 2) {
      history(auth?.clientRoutes?.facilities);
      return false;
    }
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick1 = (event) => {
    getCountry(selectCode);
    setAnchor(!anchor);
    setAnchorEl1(event.currentTarget);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(true);
  };
  const handleDrawerToggleClose = () => {
    setMobileOpen(false);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
    setAnchor(!anchor);
  };

  const handlePropertyOpen = () => {
    setModal(true);
  };

  const handlePropertyModalClose = () => {
    setModal(false);
  };

  const handleCountryModalClose = () => {
    setCountryModal(false);
  };

  const handleLanguageModalClose = () => {
    setLanguageModal(false);
  };
  const handleSignInModalClose = () => {
    setSignInModal(false);
  };
  const handleSignInOpen = () => {
    setSignInModal(true);
    setAnchorEl(null);
  };

  const handleModal = (event) => {
    setmodel(event.currentTarget);
  };

  const handleModalClose = () => {
    setmodel(null);
  };

  //logout
  const Logout = async () => {
    try {
      // await signOut();
      const id = localStorage.getItem(LocalStorageKeys.user_Id);
      await logOut({ user_id: id });
      localStorage.clear();
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.success,
        msg: t("User Logged Out successfully"),
      });
      localStorage.setItem(
        LocalStorageKeys.selected_currency,
        JSON.stringify(selectedCurrency)
      );
      setmodel(false);
      // history(auth?.clientRoutes?.listing)
    } catch (error) {
      console.error("error signing out: ", error);
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("error signing out"),
      });
    }
  };

  //getCurrentLocation
  const getCurrentLocation = async () => {
    if (device?.length > 0 && device === "mobile") {
      localStorage.setItem(
        LocalStorageKeys.selected_country,
        queryObject?.code
      );
      localStorage.setItem(
        LocalStorageKeys.current_location,
        JSON.stringify(queryObject)
      );
      getCountry(queryObject);
    } else {
      const locationdata = await getCoords();
      localStorage.setItem(
        LocalStorageKeys.selected_country,
        locationdata?.code
      );
      localStorage.setItem(
        LocalStorageKeys.current_location,
        JSON.stringify(locationdata)
      );
      getCountry(locationdata);
    }
  };

  //PropertyHeader
  const PropertyHeader = (
    <Box sx={{ display: "flex", gap: 1 }}>
      <KeysIcon />
      <Box>
        <Typography sx={topBarStyles.propertyListHeader}>
          {t("List Your Property")}
        </Typography>
        <Typography sx={topBarStyles.propertyListsubHead}>
          {t("Contact our sale team to list your property")}
        </Typography>
      </Box>
    </Box>
  );

  //CountryHeader mobile
  const CountryHeader = (
    <Box>
      <DefautTab
        tabs={["Country"]}
        value={value}
        handleChange={handleTabChange}
      />
    </Box>
  );
  //CountryComponent
  const CountryComponent = (
    <Box sx={topBarStyles.country}>
      <Typography sx={topBarStyles.countrySelect}>
        {t("Select Your Country")}
      </Typography>
      <Typography sx={topBarStyles.countrySelectSub}>
        {t("Suggested For you")}
      </Typography>
      <Grid container spacing={2}>
        {CountryDetails?.map((val) => (
          <Grid item lg={3} md={3} sm={6} xs={6}>
            <CountryCard
              url={val.url}
              label={val.label}
              country
              value={val}
              selected={selectedCountry?.label}
              selection={(v) => updateCountry(v)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  //LanguageHeader
  const LanguageHeader = (
    <Box>
      <DefautTab
        tabs={["Language"]}
        value={value}
        handleChange={handleTabChange}
      />
    </Box>
  );
  //LanguageComponent
  const LanguageComponent = (
    <Box sx={topBarStyles.country}>
      <Typography sx={topBarStyles.countrySelect}>
        {t("Select Your Language")}
      </Typography>
      <Typography sx={topBarStyles.countrySelectSub}>
        {t("Suggested For you")}
      </Typography>
      <Grid container spacing={2}>
        {LanguageDetails?.map((val) => (
          <>
            <Grid item lg={3} md={3} sm={6} xs={6}>
              <CountryCard
                url={val.url}
                label={val.label}
                label2={val.label2}
                value={val.value}
                selected={selectedLanguage?.label}
                selection={(v) => updateLanguage(v)}
              />
            </Grid>
          </>
        ))}
      </Grid>
    </Box>
  );

  //LangCountryCurrencyHeader
  const LangCountryCurrencyHeader = (
    <Box display={"flex"}>
      <DefautTab
        tabs={["Country", "Language", "Currrency", "Measure"]}
        value={value}
        variant="scrollable"
        handleChange={handleTabChange}
        tabBottomColor={"#000"}
      />
    </Box>
  );

  // LangcountryCurrency  tab mobile
  const CountryDetail = () => {
    return (
      <Box sx={topBarStyles.pad}>
        {CountryDetails?.map((val) => (
          <Box
            sx={
              selectedCountry?.label === val?.label
                ? topBarStyles.selectedmob
                : topBarStyles.unselected
            }
            onClick={() => updateCountry(val)}
            p={2}
          >
            <Avatar src={val?.url} sx={topBarStyles.countryAvatar}></Avatar>
            <Typography
              sx={
                selectedCountry?.label === val?.label
                  ? topBarStyles.langSelectedmob
                  : topBarStyles.langmob
              }
            >
              {val?.label}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  };

  // LangcountryCurrency  tab mobile
  const LanguageDetail = () => {
    return (
      <Box sx={topBarStyles.pad}>
        {LanguageDetails?.map((val) => (
          <Box
            sx={
              selectedLanguage?.label === val?.label
                ? topBarStyles.selectedmob
                : topBarStyles.unselected
            }
            onClick={() => updateLanguage(val)}
          >
            <Typography
              sx={
                selectedLanguage?.label === val?.label
                  ? topBarStyles.langSelectedmob
                  : topBarStyles.langmob
              }
            >
              {val?.label}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  };

  // LangcountryCurrency  tab mobile
  const CurrencyDetail = () => {
    return currency?.data?.map((item) => {
      return (
        <Box
          sx={
            selectedCurrency?.currency_code === item?.currency_code
              ? topBarStyles.selected
              : topBarStyles.unselected
          }
          mt={"12px"}
          onClick={() => updateCurrency(item)}
        >
          <Typography
            sx={
              selectedCurrency?.currency_id === item?.currency_id
                ? topBarStyles.langSelected
                : topBarStyles.lang
            }
          >
            {item?.currency_code}
          </Typography>
        </Box>
      );
    });
  };
  // Area  measurement  fn
  const MeasureDetail = () => {
    return (
      <>
        {area?.data?.map((item) => {
          return (
            <Box
              sx={
                selectedMetric?.name === item?.name
                  ? topBarStyles.selected
                  : topBarStyles.unselected
              }
              mt={"12px"}
              onClick={() => updateMetric(item)}
            >
              <Typography
                sx={
                  selectedMetric?.id === item?.id
                    ? topBarStyles.langSelected
                    : topBarStyles.lang
                }
              >
                {item?.name}
              </Typography>
            </Box>
          );
        })}
      </>
    );
  };

  // LangcountryCurrency  tab mobile
  const countryTab = (
    <Box sx={topBarStyles.dataBox}>
      {value === 0 && CountryDetail()}
      {value === 1 && LanguageDetail()}
      {value === 2 && CurrencyDetail()}
      {value === 3 && MeasureDetail()}
    </Box>
  );

  // revamp language country currency
  const LangCountryCurrency = (
    <Box sx={topBarStyles.contentBox}>
      <Grid container spacing={2}>
        <Grid item xs={3.6}>
          <Typography sx={topBarStyles.title}>{t("Country")}</Typography>
          <Box sx={topBarStyles.dataBox}>
            {CountryDetails?.map((val) => {
              return (
                <Box
                  sx={
                    selectedCountry?.label === val?.label
                      ? topBarStyles.selected
                      : topBarStyles.unselected
                  }
                  onClick={() => updateCountry(val)}
                >
                  <Avatar
                    src={val?.url}
                    sx={topBarStyles.countryAvatar}
                  ></Avatar>
                  <Tooltip title={val?.label}>
                    <Typography
                      sx={
                        selectedCountry?.id === val?.id
                          ? topBarStyles.langSelected
                          : topBarStyles.lang
                      }
                    >
                      {val?.label}
                    </Typography>
                  </Tooltip>
                </Box>
              );
            })}
          </Box>
        </Grid>
        <Grid item xs={0.8}>
          <Divider sx={topBarStyles.div} orientation="vertical" />
        </Grid>
        <Grid item xs={2}>
          <Typography sx={topBarStyles.title}>{t("Language")}</Typography>
          <Box>
            {LanguageDetails?.map((val) => (
              <Box
                sx={
                  selectedLanguage?.label === val?.label
                    ? topBarStyles.selected
                    : topBarStyles.unselected
                }
                onClick={() => updateLanguage(val)}
              >
                <Typography
                  sx={
                    selectedLanguage?.label === val?.label
                      ? topBarStyles.langSelected
                      : topBarStyles.lang
                  }
                >
                  {val?.label}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item xs={0.8}>
          <Divider sx={topBarStyles.div} orientation="vertical" />
        </Grid>
        <Grid item xs={2}>
          <Typography sx={topBarStyles.title}>{t("Currency")}</Typography>
          {currency?.data?.map((item) => {
            return (
              <Box
                sx={
                  selectedCurrency?.currency_code === item?.currency_code
                    ? topBarStyles.selected
                    : topBarStyles.unselected
                }
                mt={"12px"}
                onClick={() => updateCurrency(item)}
              >
                <Typography
                  sx={
                    selectedCurrency?.currency_code === item?.currency_code
                      ? topBarStyles.langSelected
                      : topBarStyles.lang
                  }
                >
                  {item?.currency_code}
                </Typography>
              </Box>
            );
          })}
        </Grid>
        <Grid item xs={0.8}>
          <Divider sx={topBarStyles.div} orientation="vertical" />
        </Grid>
        <Grid item xs={2} pl={"8px ! important"}>
          <Typography sx={topBarStyles.title}>{t("UOM")}</Typography>
          <Box sx={topBarStyles.dataBox}>
            {area?.data?.map((item) => {
              return (
                <Box
                  sx={
                    selectedMetric?.name === item?.name
                      ? topBarStyles.selected
                      : topBarStyles.unselected
                  }
                  mt={"12px"}
                  onClick={() => updateMetric(item)}
                >
                  <Typography
                    sx={
                      selectedMetric?.name === item?.name
                        ? topBarStyles.langSelected
                        : topBarStyles.lang
                    }
                  >
                    {item?.name}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  //SignInHeader may be use later
  // const SignInHeader = (
  //   <Box>
  //     <Typography sx={topBarStyles.headerText}>{t("Sign In")}</Typography>
  //   </Box>
  // );

  //onRedirect
  const onRedirect = () => {
    history(auth?.clientRoutes?.listing);
  };
  const id1 = open1 ? "simple-popover" : undefined;

  const logoConfiguration = {
    [publicListingsUrl]: <ListingsLogo onClick={onRedirect} />,
    // ["http://localhost:3001"]: <ListingsLogo onClick={onRedirect} />,
    [realityUrl]: <Logo onClick={onRedirect} />,
    [amenitiesUrl]: <AmenetiesGotoIcon onClick={onRedirect} />,
  };
  const logoConfigurationMobile = {
    [publicListingsUrl]: <ListingsLogo onClick={onRedirect} height={"40px"} />,
    // ["http://localhost:3001"]: <ListingsLogo onClick={onRedirect} height={'40px'} />,
    [realityUrl]: <Logo onClick={onRedirect} height={"40px"} />,
    [amenitiesUrl]: <AmenetiesGotoIcon onClick={onRedirect} height={"40px"} />,
  };

  return (
    <div>
      {/* <Container sx={{ maxWidth: "1940px", padding: "0px !important" }}> */}
      <Hidden smDown>
        <AppBar sx={topBarStyles.appBarSx}>
          <Toolbar sx={topBarStyles.toolbarSx}>
            <Grid container sx={topBarStyles.spacebt}>
              <Grid
                item
                md={10}
                lg={10}
                xl={10}
                sm={10}
                sx={topBarStyles.logoBox}
              >
                <Grid item xl={4} lg={4} md={3} sm={3}>
                  <Box sx={topBarStyles.Logo}>
                    {(client?.length && client) ||
                    !host_arr.includes(window.location.origin) ? (
                      props?.assetsAndLinks?.client_logo?.url ? (
                        <img
                          onClick={onRedirect}
                          src={props?.assetsAndLinks?.client_logo?.url}
                          alt="logo"
                          style={{
                            width: 138,
                            height: 40,
                          }}
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      logoConfiguration[hostName]
                    )}
                    {/* ameneties goto Logo pls Don't delete it */}
                  </Box>
                </Grid>
                <Grid item xl={8} lg={8} md={9} sm={9}>
                  {toggle === 5 ? null : (
                    <TabComponent
                      t={t}
                      handleChange={handleToggleChange}
                      value={toggle}
                      onClick={handleToggleChange}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                md={2}
                lg={2}
                xl={2}
                sm={2}
                sx={topBarStyles.spacebtItem}
              >
                <Hidden mdDown>
                  <Box sx={topBarStyles.titleSx}>
                    {/* <Button variant="text" sx={topBarStyles.buttonSx}>
                    {t("Support")}
                  </Button> */}
                    {/* revamp card */}
                    <Box
                      sx={
                        anchor
                          ? topBarStyles.menuBorder
                          : topBarStyles.selectMenu
                      }
                      onClick={handleClick1}
                    >
                      <Avatar
                        src={"/images/worldcon.svg"}
                        sx={topBarStyles.countryAvatar}
                      ></Avatar>
                      <Divider
                        sx={topBarStyles.divider}
                        orientation="vertical"
                      />
                      <Typography sx={topBarStyles.enText}>
                        {selectedLanguage?.value}
                      </Typography>
                      <Divider
                        sx={topBarStyles.divider}
                        orientation="vertical"
                      />
                      <Typography sx={topBarStyles.enText}>
                        {selectedCurrency?.currency_code}
                      </Typography>
                      <Divider
                        sx={topBarStyles.divider}
                        orientation="vertical"
                      />
                      <Typography sx={topBarStyles.enText}>
                        {selectedMetric?.name}
                      </Typography>
                      {anchor ? (
                        <ArrowDropUpIcon sx={topBarStyles.arrow} />
                      ) : (
                        <ArrowDropDownIcon sx={topBarStyles.arrow} />
                      )}
                    </Box>
                    <Popover
                      id={id1}
                      open={open1}
                      anchorEl={anchorEl1}
                      onClose={handleClose1}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Box>{LangCountryCurrency}</Box>
                    </Popover>
                    <Divider
                      sx={topBarStyles.dividerSx}
                      orientation="vertical"
                    />
                    {/* {/* {
                      selectedCountry?.label?.length > 0 ?
                        <Stack direction={"row"} alignItems={"center"} sx={topBarStyles.contryBox} onClick={() => handleCountryModal()}>
                          <Avatar src={selectedCountry?.url} sx={topBarStyles.countryAvatar}></Avatar>
                          <Typography sx={topBarStyles.countryLabel}> {selectedCountry?.label} </Typography>
                        </Stack>
                        :
                        <Button
                          variant="text"
                          sx={topBarStyles.buttonSx}
                          onClick={() => handleCountryModal()}
                        >
                          {t("Country")}
                        </Button>
                    }
                    <IconButton
                      aria-label="logout button"
                      aria-controls={"logout_button"}
                      aria-haspopup="true"
                      color="inherit"
                      size="small"
                      onClick={() => handleLanguageModal()}
                    >
                      <GlobeIcon />
                    </IconButton>
                    <CustomButton
                      color="primary"
                      variant="contained"
                      size="small"
                      label={t("List Your Property")}
                      onClick={handlePropertyOpen}
                      sx={topBarStyles.listText}
                    /> */}
                    {localStorage.getItem(LocalStorageKeys?.username) ? (
                      <>
                        <Chip
                          avatar={
                            <Avatar
                              alt={localStorage.getItem(
                                LocalStorageKeys?.username
                              )}
                              src="/broken-image.jpg"
                              sx={topBarStyles.avatarIcon}
                            ></Avatar>
                          }
                          variant="outlined"
                          label={" "}
                          onDelete={handleModal}
                          deleteIcon={
                            <ArrowDropDown style={{ marginInlineEnd: "6px" }} />
                          }
                          sx={topBarStyles.chipIcon}
                        />
                        <Menu
                          id="basic-menu"
                          anchorEl={modelOpen}
                          open={openModel}
                          onClose={handleModalClose}
                          sx={topBarStyles.menu}
                        >
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            padding={"12px 14px 4px"}
                          >
                            <Box sx={topBarStyles.nameBox}>
                              <Avatar
                                sx={topBarStyles.avtlogo}
                                alt={localStorage.getItem(
                                  LocalStorageKeys?.username
                                )}
                                src="/broken-image.jpg"
                              ></Avatar>
                              <Typography sx={topBarStyles.signinTitleWeb}>
                                {localStorage.getItem(
                                  LocalStorageKeys?.username
                                )}
                              </Typography>
                            </Box>
                          </Stack>
                          {userSnippet?.map((e, i) => {
                            return (
                              <MenuItem sx={topBarStyles.menuBox}>
                                <Stack
                                  width={"100%"}
                                  direction={"row"}
                                  alignItems={"center"}
                                  sx={{
                                    borderBottom:
                                      userSnippet.length - 1 === i
                                        ? ""
                                        : "1px solid #CED3DD",
                                  }}
                                  onClick={
                                    e?.route === "delete"
                                      ? () => setDeleteModal(true)
                                      : e?.route === "SignOut"
                                      ? Logout
                                      : () =>
                                          navigate(
                                            e?.id === 1
                                              ? auth.clientRoutes.myShortlist
                                              : e?.id === 2
                                              ? auth.clientRoutes.shortlist
                                              : e?.id === 3
                                              ? auth.clientRoutes.confirmBooking
                                              : e?.id === 4
                                              ? "delete"
                                              : e?.id === 5
                                              ? "SignOut"
                                              : e?.route
                                          )
                                  }
                                >
                                  {e?.icon}
                                  <Typography
                                    sx={
                                      i + 1 === userSnippet?.length
                                        ? topBarStyles.menuListActive
                                        : topBarStyles.menuList
                                    }
                                    ml={1}
                                  >
                                    {t(e?.label)}
                                  </Typography>
                                </Stack>
                              </MenuItem>
                            );
                          })}
                        </Menu>
                      </>
                    ) : (
                      <CustomButton
                        variant="outlined"
                        label={t("Sign in")}
                        onClick={handleSignInOpen}
                        rootStyle={topBarStyles.signinSx}
                      />
                    )}
                  </Box>
                </Hidden>
                {/* tab logout popover */}
                <Hidden mdUp>
                  {localStorage.getItem(LocalStorageKeys?.username) ? (
                    <>
                      <Chip
                        avatar={
                          <Avatar
                            alt={localStorage.getItem(
                              LocalStorageKeys?.username
                            )}
                            src="/broken-image.jpg"
                            sx={topBarStyles.avatarIcon}
                          ></Avatar>
                        }
                        variant="outlined"
                        label={" "}
                        onDelete={handleModal}
                        deleteIcon={
                          <ArrowDropDown style={{ marginInlineEnd: "6px" }} />
                        }
                        sx={topBarStyles.chipIcon}
                      />
                      <Menu
                        id="basic-menu"
                        anchorEl={modelOpen}
                        open={openModel}
                        onClose={handleModalClose}
                        sx={topBarStyles.menu}
                      >
                        <Box padding={"12px 14px 4px"}>
                          <Box
                            sx={
                              anchor
                                ? topBarStyles.menuBorder
                                : topBarStyles.selectMenu
                            }
                            onClick={handleClick1}
                          >
                            <Avatar
                              src={selectedCountry?.url}
                              sx={topBarStyles.countryAvatar}
                            ></Avatar>
                            <Divider
                              sx={topBarStyles.divider}
                              orientation="vertical"
                            />
                            <Typography sx={topBarStyles.enText}>
                              {selectedLanguage?.value}
                            </Typography>
                            <Divider
                              sx={topBarStyles.divider}
                              orientation="vertical"
                            />
                            <Typography sx={topBarStyles.enText}>
                              {selectedCurrency?.currency_code}
                            </Typography>
                            <Divider
                              sx={topBarStyles.divider}
                              orientation="vertical"
                            />
                            <Typography sx={topBarStyles.enText}>
                              {selectedMetric?.name}
                            </Typography>
                            {anchor ? (
                              <ArrowDropUpIcon sx={topBarStyles.arrow} />
                            ) : (
                              <ArrowDropDownIcon sx={topBarStyles.arrow} />
                            )}
                          </Box>
                        </Box>
                        <Popover
                          id={id1}
                          open={open1}
                          anchorEl={anchorEl1}
                          onClose={handleClose1}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <Box>{LangCountryCurrency}</Box>
                        </Popover>
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          padding={"12px 14px 4px"}
                        >
                          <Box sx={topBarStyles.nameBox}>
                            <Avatar
                              sx={topBarStyles.avtlogo}
                              alt={localStorage.getItem(
                                LocalStorageKeys?.username
                              )}
                              src="/broken-image.jpg"
                            ></Avatar>
                            <Typography sx={topBarStyles.signinTitleWeb}>
                              {localStorage.getItem(LocalStorageKeys?.username)}
                            </Typography>
                          </Box>
                        </Stack>

                        {userSnippet?.map((e, i) => {
                          return (
                            <>
                              <MenuItem sx={topBarStyles.menuBox}>
                                <Stack
                                  width={"100%"}
                                  direction={"row"}
                                  alignItems={"center"}
                                  sx={{
                                    borderBottom:
                                      userSnippet.length - 1 === i
                                        ? ""
                                        : "1px solid #CED3DD",
                                  }}
                                  onClick={
                                    e?.route === "delete"
                                      ? () => setDeleteModal(true)
                                      : e?.route === "SignOut"
                                      ? Logout
                                      : () => navigate(e?.route)
                                  }
                                >
                                  {e?.icon}
                                  <Typography
                                    sx={
                                      i + 1 === userSnippet?.length
                                        ? topBarStyles.menuListActive
                                        : topBarStyles.menuList
                                    }
                                    ml={1}
                                  >
                                    {t(e?.label)}
                                  </Typography>
                                </Stack>
                              </MenuItem>
                            </>
                          );
                        })}
                      </Menu>
                    </>
                  ) : (
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <Hamburger style={topBarStyles?.menuIconMob} />
                      <Menu
                        id="long-menu"
                        MenuListProps={{
                          "aria-labelledby": "long-button",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        sx={topBarStyles.menu}
                      >
                        <MenuList sx={topBarStyles?.menuListStyles}>
                          <Box padding={"12px 14px 10px 4px"}>
                            <Box
                              sx={
                                anchor
                                  ? topBarStyles.menuBorder
                                  : topBarStyles.selectMenu
                              }
                              onClick={handleClick1}
                            >
                              <Avatar
                                src={selectedCountry?.url}
                                sx={topBarStyles.countryAvatar}
                              ></Avatar>
                              <Divider
                                sx={topBarStyles.divider}
                                orientation="vertical"
                              />
                              <Typography sx={topBarStyles.enText}>
                                {selectedLanguage?.value}
                              </Typography>
                              <Divider
                                sx={topBarStyles.divider}
                                orientation="vertical"
                              />
                              <Typography sx={topBarStyles.enText}>
                                {selectedCurrency?.currency_code}
                              </Typography>
                              <Divider
                                sx={topBarStyles.divider}
                                orientation="vertical"
                              />
                              <Typography sx={topBarStyles.enText}>
                                {selectedMetric?.name}
                              </Typography>
                              {anchor ? (
                                <ArrowDropUpIcon sx={topBarStyles.arrow} />
                              ) : (
                                <ArrowDropDownIcon sx={topBarStyles.arrow} />
                              )}
                            </Box>
                          </Box>
                          <Popover
                            id={id1}
                            open={open1}
                            anchorEl={anchorEl1}
                            onClose={handleClose1}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                          >
                            <Box>{LangCountryCurrency}</Box>
                          </Popover>
                          <MenuItem sx={topBarStyles.menuBox}>
                            <CustomButton
                              variant="outlined"
                              label={t("Sign in")}
                              onClick={handleSignInOpen}
                              rootStyle={topBarStyles.signinSx}
                            />
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </IconButton>
                  )}
                </Hidden>
              </Grid>
            </Grid>

            <DialogBox
              open={modal}
              handleClose={handlePropertyModalClose}
              maxWidth="xs"
              header={PropertyHeader}
              component={<EnquiryForm />}
            />
            <DialogBox
              open={countryModal}
              handleClose={handleCountryModalClose}
              maxWidth="md"
              header={CountryHeader}
              component={CountryComponent}
              isDivided
              height={"400px"}
            />
            <DialogBox
              open={languageModal}
              handleClose={handleLanguageModalClose}
              maxWidth="md"
              header={LanguageHeader}
              component={LanguageComponent}
              isDivided
              height={"400px"}
            />
          </Toolbar>
        </AppBar>
      </Hidden>

      {/* for mobile */}
      <Hidden smUp>
        <AppBar sx={topBarStyles.appBarSx}>
          <Toolbar sx={topBarStyles.toolbarSx}>
            <Stack
              flexDirection={"row-reverse"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Stack>
                <Hamburger
                  onClick={handleDrawerToggle}
                  style={topBarStyles?.menuIconMob}
                />
              </Stack>
              <Stack>
                <Box sx={topBarStyles.Logo}>
                  {(client?.length && client) ||
                  !host_arr.includes(window.location.origin) ? (
                    props?.assetsAndLinks?.client_logo?.url ? (
                      <img
                        src={props?.assetsAndLinks?.client_logo?.url}
                        alt="logo"
                        onClick={onRedirect}
                        style={{ width: 138, height: 40, objectFit: "contain" }}
                      />
                    ) : (
                      ""
                    )
                  ) : (
                    logoConfiguration[hostName]
                  )}
                  {/* {((((client?.length === 0 || client === null ||client === undefined ) || !client)||(!(host_arr.includes(window.location.origin)))) && logoConfiguration[hostName])} */}
                </Box>
              </Stack>
            </Stack>

            <DialogBox
              open={modal}
              handleClose={handlePropertyModalClose}
              maxWidth="xs"
              header={PropertyHeader}
              component={<EnquiryForm />}
            />
            <DialogBox
              open={countryModal}
              handleClose={handleCountryModalClose}
              maxWidth="md"
              header={CountryHeader}
              component={CountryComponent}
              isDivided
              height={"400px"}
            />
            <DialogBox
              open={languageModal}
              handleClose={handleLanguageModalClose}
              maxWidth="md"
              header={LanguageHeader}
              component={LanguageComponent}
              isDivided
              height={"400px"}
            />

            <DialogBox
              open={signInModal}
              handleClose={handleSignInModalClose}
              maxWidth="xs"
              component={<SignIn isPadding onClose={handleSignInModalClose} />}
              header={t("Sign In")}
              height={"100% important"}
            />

            <DialogBox
              open={open1}
              handleClose={handleClose1}
              maxWidth="md"
              header={LangCountryCurrencyHeader}
              component={countryTab}
              isDivided
              dialogpadding={{ padding: "8px" }}
            />
            {/* mobile view drawer */}
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggleClose}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={topBarStyles?.mobDrawer}
            >
              <Box p={2}>
                {toggle === 5 ? null : (
                  <TabComponent
                    t={t}
                    handleChange={handleToggleChange}
                    value={toggle}
                    onClick={handleToggleChange}
                  />
                )}
                <Box onClick={handlePropertyOpen} sx={topBarStyles.propertyBox}>
                  {/* {t("List Your Property")} */}
                  {/* <Stack direction={"row"} spacing={2} alignItems={"center"} justifyContent={"space-between"}>
                    <Stack direction={"row"} alignItems={"center"}>
                      <HouseDollar />
                      <Stack sx={{ marginInlineStart: "16px" }}>
                        <Typography sx={topBarStyles.propertyListHeader}>{t("List your property")}</Typography>
                        <Typography sx={topBarStyles.propertyListsubHead2}>{t("Sale, lease, rent your property with us")}</Typography>
                      </Stack>
                    </Stack>
                    <Stack>
                      <ArrowRight style={{ transform: language === 'ar' ? "rotate(180deg)" : '' }} />
                    </Stack>
                  </Stack> */}
                </Box>

                {/* mobile menu for language */}
                <Stack>
                  {localStorage.getItem(LocalStorageKeys?.username) ? (
                    <>
                      <Typography sx={topBarStyles.ya}>
                        {t("Your Account")}
                      </Typography>
                      <Box sx={topBarStyles.yourAcc}>
                        <Stack direction={"row"} alignItems={"center"}>
                          <Avatar
                            alt={localStorage.getItem(
                              LocalStorageKeys?.username
                            )}
                            src="/broken-image.jpg"
                          ></Avatar>
                          <Typography sx={topBarStyles.signinTitle}>
                            {localStorage.getItem(LocalStorageKeys?.username)}
                          </Typography>
                        </Stack>
                        <Divider />
                        {userSnippetMob?.map((e, i) => {
                          return (
                            <>
                              {i + 1 === userSnippet?.length ? <Divider /> : ""}
                              <Stack
                                width={"100%"}
                                direction={"row"}
                                alignItems={"center"}
                                onClick={
                                  e?.route === "delete"
                                    ? () => setDeleteModal(true)
                                    : e?.route === "SignOut"
                                    ? Logout
                                    : () =>
                                        navigate(
                                          e?.id === 1
                                            ? auth.clientRoutes.myShortlist
                                            : e?.id === 2
                                            ? auth.clientRoutes.shortlist
                                            : e?.id === 3
                                            ? auth.clientRoutes.confirmBooking
                                            : e?.id === 4
                                            ? "delete"
                                            : e?.route
                                        )
                                }
                              >
                                {e?.icon}
                                <Typography sx={topBarStyles.menuListMob}>
                                  {t(e?.label)}
                                </Typography>
                              </Stack>
                            </>
                          );
                        })}
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        {logout?.map((e, i) => {
                          return (
                            <>
                              {i + 1 === userSnippet?.length ? <Divider /> : ""}
                              <Stack
                                width={"100%"}
                                direction={"row"}
                                alignItems={"center"}
                                onClick={
                                  e?.route === "delete"
                                    ? () => setDeleteModal(true)
                                    : e?.route === "SignOut"
                                    ? Logout
                                    : () => navigate(e?.route)
                                }
                              >
                                {e?.icon}
                                <Typography sx={topBarStyles.menuListActiveMob}>
                                  {t(e?.label)}
                                </Typography>
                              </Stack>
                            </>
                          );
                        })}

                        <Box
                          sx={
                            anchor
                              ? topBarStyles.menuBorder
                              : topBarStyles.selectMenu
                          }
                          onClick={handleClick1}
                          mt={1.5}
                        >
                          <Avatar
                            src={selectedCountry?.url}
                            sx={topBarStyles.countryAvatar}
                          ></Avatar>
                          <Divider
                            sx={topBarStyles.divider}
                            orientation="vertical"
                          />
                          <Typography sx={topBarStyles.enText}>
                            {selectedLanguage?.value}
                          </Typography>
                          <Divider
                            sx={topBarStyles.divider}
                            orientation="vertical"
                          />
                          <Typography sx={topBarStyles.enText}>
                            {selectedCurrency?.currency_code}
                          </Typography>
                          <Divider
                            sx={topBarStyles.divider}
                            orientation="vertical"
                          />
                          <Typography sx={topBarStyles.enText}>
                            {selectedMetric?.name}
                          </Typography>
                          {anchor ? (
                            <ArrowDropUpIcon sx={topBarStyles.arrow} />
                          ) : (
                            <ArrowDropDownIcon sx={topBarStyles.arrow} />
                          )}
                        </Box>
                      </Box>
                      {/* </Drawer> */}
                    </>
                  ) : (
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Box
                        sx={
                          anchor
                            ? topBarStyles.menuBorder
                            : topBarStyles.selectMenu
                        }
                        onClick={handleClick1}
                      >
                        <Avatar
                          src={selectedCountry?.url}
                          sx={topBarStyles.countryAvatar}
                        ></Avatar>
                        <Divider
                          sx={topBarStyles.divider}
                          orientation="vertical"
                        />
                        <Typography sx={topBarStyles.enText}>
                          {selectedLanguage?.value}
                        </Typography>
                        <Divider
                          sx={topBarStyles.divider}
                          orientation="vertical"
                        />
                        <Typography sx={topBarStyles.enText}>
                          {selectedCurrency?.currency_code}
                        </Typography>
                        <Divider
                          sx={topBarStyles.divider}
                          orientation="vertical"
                        />
                        <Typography sx={topBarStyles.enText}>
                          {selectedMetric?.name}
                        </Typography>
                        {anchor ? (
                          <ArrowDropUpIcon sx={topBarStyles.arrow} />
                        ) : (
                          <ArrowDropDownIcon sx={topBarStyles.arrow} />
                        )}
                      </Box>
                      <CustomButton
                        variant="outlined"
                        label={t("Sign in")}
                        onClick={handleSignInOpen}
                        rootStyle={topBarStyles.signinSx}
                      />
                    </Box>
                  )}
                </Stack>
              </Box>
            </Drawer>
          </Toolbar>
        </AppBar>
      </Hidden>
      {/* Sign In Drawer */}
      <DialogBox
        open={signInModal}
        handleClose={handleSignInModalClose}
        maxWidth="xs"
        component={
          <SignIn
            updateData={updateData}
            data={data}
            loading={loading}
            isPadding
            onClose={handleSignInModalClose}
            handleClickShowPassword={handleClickShowPassword}
            Login={Login}
          />
        }
        header={t("Sign In")}
        height={"100% important"}
      />
      {/* delete account Drawer */}
      <DialogBox
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        maxWidth="xs"
        isnotTitle
        component={
          <>
            <Box height={"120px"} p={2}>
              <Grid container>
                <Grid items xs={12} marginY={"10px"}>
                  <Typography>
                    {t("Are you sure want to delete the account...?")}
                  </Typography>
                </Grid>

                <Grid item xs={6} textAlign={"left"} marginY={"6px"}>
                  <CustomButton
                    onClick={() => setDeleteModal(false)}
                    label={t("No")}
                  />
                </Grid>
                <Grid item xs={6} textAlign={"right"} marginY={"6px"}>
                  <CustomButton
                    variant="outlined"
                    onClick={deleteUserAccount}
                    label={t("Yes, I  want to delete")}
                  />
                </Grid>
              </Grid>
            </Box>
          </>
        }
        height={"100% important"}
      />
      {/* </Container> */}
    </div>
  );
};
