import { borderRadius } from "../../utils";
import { FontFamilySwitch } from "../../utils/font";

export const CountryCardStyle = {
  maincardsx: {
    borderRadius: borderRadius,
    display: "flex",
    alignItems: "center",
    padding: "8px",
    backgroundColor: "#F7F7F7",
    height: "65px",
  },
  maincardSelectedLanguage: {
    borderRadius: borderRadius,
    alignItems: "center",
    padding: "8px",
    backgroundColor: "#fff",
    height: "65px",
    boxShadow: "0px 3px 6px #00000029",
    border: "1px solid #1F74EA",
  },
  maincardSelectedCountry: {
    borderRadius: borderRadius,
    alignItems: "center",
    padding: "8px",
    backgroundColor: "#fff",
    height: "65px",
    boxShadow: "0px 3px 6px #00000029",
    display: "flex",
    border: "1px solid #1F74EA",
  },
  text: {
    marginInlineStart: "10px",
    textAlign: "initial",
    fontSize:  "0.875rem",
    fontFamily: FontFamilySwitch().regular,
  },
  cardsx: {
    borderRadius: borderRadius,
    padding: "8px",
    backgroundColor: "#F7F7F7",
    height: "65px",
  },
  textlanguage: {
    color: "#98A0AC",
    textAlign: "initial",
    marginInlineStart: "10px",
    fontSize:  "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
};
