import React, { Suspense } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { AppRoutes } from "./routes";
import PrivateRouter from "./privateRouter";

import { NotFound } from "./../screens";
import { NewLoader } from "../components/newLoader";
import { AuthContext } from "../contexts";
import { config } from "../config";

const Home = React.lazy(() => import("./../screens/home/home"));
// const ListingSearch = React.lazy(() => import("./../screens/listingSearch/listingSearch"))
const ListingSearch = React.lazy(() =>
  import("./../screens/enhancement_screen/listingSearch/listingSearch")
);
// const ListingPage = React.lazy(() => import("./../screens/publicListing/listing"))
const ListingPage = React.lazy(() =>
  import("./../screens/enhancement_screen/publicListing/listing")
);
const StayScreen = React.lazy(() =>
  import("./../screens/staysScreen/stayscreen")
);
// const CommercialSearch = React.lazy(() => import("./../screens/commercialSearch/commercialSearch"))
const CommercialSearch = React.lazy(() =>
  import("./../screens/enhancement_screen/commercialSearch/commercialSearch")
);
const StaySearch = React.lazy(() =>
  import("./../screens/staysSearch/parentSearch")
);
// const ResidentialViewPage = React.lazy(() => import("./../screens/residentialView/residential"))
const ResidentialViewPage = React.lazy(() =>
  import("./../screens/enhancement_screen/residentialView/residential")
);
const CommercialView = React.lazy(() =>
  import("./../screens/enhancement_screen/commmercialView/commercial")
);
// const CommercialView = React.lazy(() => import("./../screens/commmercialView/commercial"))
// const Facilities = React.lazy(() => import("./../screens/facilities/facilities"))
const Facilities = React.lazy(() =>
  import("./../screens/enhancement_screen/facilities/facilities")
);
const Commercial = React.lazy(() =>
  import("./../screens/enhancement_screen/commercial/commercial")
);
const StaysViewPage = React.lazy(() =>
  import("./../screens/staysView/staysview")
);
// const Commercial = React.lazy(() => import("./../screens/commercial/commercial"))
const BookingFlow = React.lazy(() =>
  import("./../screens/bookingFlow/bookingFlow")
);
const BookingCompleted = React.lazy(() =>
  import("./../screens/bookingCompleted/bookingCompleted")
);
// const FacilitiesBooking = React.lazy(() => import("./../screens/facilityBooking/facilityBooking"))
const FacilitiesBooking = React.lazy(() =>
  import("./../screens/enhancement_screen/facilityBooking/facilityBooking")
);
// const ConfirmBooking = React.lazy(() => import("./../screens/confirmBooking/confirmBooking"))
const ConfirmBooking = React.lazy(() =>
  import("./../screens/enhancement_screen/confirmBooking/confirmBooking")
);
// const SearchResults = React.lazy(() => import("./../screens/facilitiesSearchResults/searchResults"))
const SearchResults = React.lazy(() =>
  import(
    "./../screens/enhancement_screen/facilitiesSearchResults/searchResults"
  )
);
// const ViewDetails = React.lazy(() => import("./../screens/viewDetails/viewDetails"))
const ViewDetails = React.lazy(() =>
  import("./../screens/enhancement_screen/viewDetails/viewDetails")
);

const ImageList = React.lazy(() => import("./../screens/imageList/imageList"));
const Login = React.lazy(() => import("./../screens/login/login"));
// const Shortlist = React.lazy(() => import("./../screens/shortlist/shortlist"))
const Shortlist = React.lazy(() =>
  import("./../screens/enhancement_screen/shortlist/shortlist")
);
const ShortListed = React.lazy(() =>
  import("./../screens/shortListed/shortListed")
);
const CheckAvailability = React.lazy(() =>
  import("./../screens/checkAvailability/checkAvailability")
);
const MyShortlistPage = React.lazy(() =>
  import("./../screens/enhancement_screen/myShortlist/myShortlist")
);

const RouterApp = (props) => {
  const auth = React.useContext(AuthContext);

  return (
    <Suspense fallback={<NewLoader />}>
      <BrowserRouter>
        <Routes>
          {/* Home Route */}

          <Route
            path={AppRoutes.home}
            element={
              <PrivateRouter path={AppRoutes.home}>
                <Home />
              </PrivateRouter>
            }
          />

          {/* Login Route */}
          <Route path={AppRoutes.login} element={<Login />} />

          {/* Listing Route */}
          <Route
            path={auth?.clientRoutes?.listing}
            element={
              window.location.origin !== config.amenitiesgoto_url ? (
                <ListingPage />
              ) : (
                <Facilities />
              )
            }
          />
          {/* <Route path={"/:name"} element={<ListingPage />} /> */}

          {/* Listing Route */}
          <Route
            path={auth?.clientRoutes?.staysScreen}
            element={<StayScreen />}
          />

          {/* Listing Search Route */}

          <Route
            path={auth?.clientRoutes?.listingSearch}
            element={<ListingSearch />}
          />

          {/* Commercial Search Route */}
          <Route
            path={auth?.clientRoutes?.commercialSearch}
            element={<CommercialSearch />}
          />

          {/* Stay Search Route */}
          <Route
            path={auth?.clientRoutes?.staysSearch}
            element={<StaySearch />}
          />

          {/* Resident View Route */}
          <Route
            path={auth?.clientRoutes?.residentView}
            element={<ResidentialViewPage />}
          />

          {/* Commercial View Route */}
          <Route
            path={auth?.clientRoutes?.commercialView}
            element={<CommercialView />}
          />

          {/* Facilities Page Route */}
          <Route
            path={auth?.clientRoutes?.facilities}
            element={<Facilities />}
          />

          {/* Resident View Route */}
          <Route
            path={auth?.clientRoutes?.staysView}
            element={<StaysViewPage />}
          />

          {/* Commercial  Route */}
          <Route
            path={auth?.clientRoutes?.commercial}
            element={<Commercial />}
          />

          {/* Stays Booking Flow */}
          <Route
            path={auth?.clientRoutes?.bookingFlow}
            element={<BookingFlow />}
          />
          {/* Stays Booking Flow */}
          <Route
            path={auth?.clientRoutes?.bookingCompleted}
            element={<BookingCompleted />}
          />

          {/* Facilities Booking Route */}
          <Route
            path={auth?.clientRoutes?.facilityBooking}
            element={<FacilitiesBooking />}
          />

          {/* Confirm Booking Route */}
          <Route
            path={auth?.clientRoutes?.confirmBooking}
            element={
              <PrivateRouter path={AppRoutes.confirmBooking}>
                <ConfirmBooking />
              </PrivateRouter>
            }
          />

          {/* Facilities search Results */}
          <Route
            path={auth?.clientRoutes?.searchResults}
            element={<SearchResults />}
          />

          {/* Facilities view Detaila */}
          <Route
            path={auth?.clientRoutes?.viewDetails}
            element={<ViewDetails />}
          />

          {/* Image List */}
          <Route path={auth?.clientRoutes?.imageList} element={<ImageList />} />

          {/* Shortlist */}
          <Route
            path={auth?.clientRoutes?.shortlist}
            element={
              <PrivateRouter path={AppRoutes.shortlist}>
                <Shortlist />
              </PrivateRouter>
            }
          />

          {/* Shortlisted */}
          <Route
            path={auth?.clientRoutes?.shortListed}
            element={<ShortListed />}
          />

          {/* CheckAvailability */}
          <Route
            path={auth?.clientRoutes?.checkAvailability}
            element={<CheckAvailability />}
          />

          {/* my shortlist */}
          {/* <Route path={auth?.clientRoutes?.myShortlist} element={<MyShortlistPage />} /> */}

          <Route
            path={auth?.clientRoutes?.myShortlist}
            element={
              <PrivateRouter path={AppRoutes.myShortlist}>
                <MyShortlistPage />
              </PrivateRouter>
            }
          />

          {/* For unknow/non-defined path */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default RouterApp;
