export const AppRoutes = {
  home: "/home",
  login: "/login",
  listing: "/",
  listingSearch: "/listingsearch",
  commercialSearch: "/commercialSearch",
  staysSearch: "/staysearch",
  staysScreen: "/staysscreen",
  staysView: "/staysview",
  residentView: "/residentview",
  commercialView: "/commercialview",
  facilities: "/facilities",
  commercial: "/commercial",
  facilityBooking: "/facilitybooking",
  confirmBooking: "/confirmbooking",
  searchResults: "/searchResults",
  viewDetails: "/viewDetails",
  bookingFlow: "/bookingFlow",
  bookingCompleted: "/bookingCompleted",
  imageList: "/imageList",
  shortlist: "/shortlist",
  shortListed: "/shortListed",
  facilityBookingDetails: "/facilityBookingDetails",
  checkAvailability: "/checkAvailability",
  myShortlist: "/myShortlist",
};
