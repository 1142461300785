import { borderRadius } from "../../utils";
import { FontFamilySwitch } from "../../utils/font";

export const mobileStyles = {
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    marginBottom: 0,
  },
  wrapper: {
    backgroundColor: '#fff',
    borderRadius: borderRadius,
    height: 40,
    alignItems: "center",

  },
  input: {
    pl: 1
  },
  limitmessage: {
    fontFamily: FontFamilySwitch().regular,
    fontSize: "0.75rem !important"
  },
  margin: {
    margin: "0px",
    height: "100%",
    width: "100%",
    "& .MuiOutlinedInput-root": {
      paddingRight: "20px !important",
      // width: '115px'
    },
  },
  label: {
    color: "rgba(146, 151, 165, 1)",
    textTransform: "uppercase",
    margin: "0px 8px",
  },
  autocomplete: {
    "& fieldset": {
      border: "none",
    },
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
      fontSize:  "0.875rem",
      padding: "0px 9px",
    },
  },
  numberBorder: {
    borderRight: "1px solid #E4E8EE",
  },
  borderColor: {
    border: "1px solid #E4E8EE",
    borderRadius: borderRadius,
    // mt: "9px",
    height: 40,
    alignItems: "center",
    backgroundColor: "#fff"
  },

}