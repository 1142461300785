import { borderRadius } from "../../utils";
import { FontFamilySwitch } from "../../utils/font";

export const dialogStyle = ({width}) =>{
  
  return {
  root: {
    padding: "0px",
    backgroundColor: '#F5F7FA',
    '::-webkit-scrollbar': {
      display: 'none'
    },
    '-ms-overflow-style': 'none',  /* IE and Edge */
    scrollbarWidth: 'none',
  },

  rootmain: {
    '& .css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
      margin: '10px',

    },
    width: "100%",
    height: '100%',
    maxWidth: "auto",
    padding: "0 !important",
    "& .MuiPaper-root": {
      width: width,
      borderRadius: (props) => props?.borderRadius ?? borderRadius,
      height: (props) => props?.height ?? 'fit-content',
    },
    "& .MuiDialogContent-root": {
      padding: "0px !important",
      position: 'relative',
      backgroundColor: '#F5F7FA',
      overflow: (props) => props?.overflow ?? 'auto',
      height: (props) => props?.height ?? 'auto',
    },
    '& .MuiDialog-container': {
      padding: (props) => props?.padding ?? '',
      alignItems: "flex-start",
      marginTop: "40px"

    }
  },
  rootmainDivided: {
    '& .css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
      margin: '10px',
    },
    width: "100%",
    height: '100%',
    maxWidth: "auto",
    padding: "0 !important",
    "& .MuiPaper-root": {
      borderRadius: (props) => props?.borderRadius ?? borderRadius,
      height: (props) => props?.height ?? 'fit-content',
    },
    "& .MuiDialogContent-root": {
      padding: "0px !important",
      position: 'relative',
      backgroundColor: "#FFFF",
      overflow: (props) => props?.overflow ?? 'auto',
      height: (props) => props?.height ?? 'auto',
    },
    "& .MuiDialogTitle-root": {
      padding: "0px 24px"
    },
    '& .MuiDialog-container': {
      padding: (props) => props?.padding ?? '',
      alignItems: "flex-start",
      marginTop: "40px"

    }
  },
  header: {
    border: "none",
    fontSize:  "1rem",
    fontFamily: FontFamilySwitch().extraBold,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  viewHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 12px !important",
  },
  head: {
    padding: "16px 16px !important"
  },
  drawer: {
    "& .MuiPaper-root": {
      height: "calc(100vh - 100px)",
      borderTopLeftRadius: "16px",
      borderTopRightRadius: "16px"

    }
  },
  drawer1: {
    "& .MuiPaper-root": {
      borderTopLeftRadius: "16px",
      borderTopRightRadius: "16px",
      // overflow:"auto"

    }
  },
  headTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  hd: {
    padding: "16px 16px 8px !important"
  },
  filt: {
    color: "#091B29",
    fontFamily: FontFamilySwitch().extraBold,
    fontSize:  "1rem"
  },
  CloseIcons: {
    cursor: "pointer",
    backgroundColor: "#F7F7F7",
    padding: "2px",
    borderRadius: "50px",
    width: "24px",
    height: "24px"
  },
  closeBox: {
    backgroundColor: "#F7F7F7",
    borderRadius: "34px",
    height: "40px",
    width: "40px"
  },
  cl: {
    ml: "14px",
    mt: "14px",
    height: "20px",
    width: "20px"
  },
  clRtl: {
    marginInlineStart: "8px",
    mt: "14px",
    height: "20px",
    width: "20px"
  }
}}