import { FontFamilySwitch } from "../../utils/font";

export const NewTabsComponent_Style = {
    tabroot: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#F8FAFC",
        borderBottom: "1px solid #CED3DD",
        minHeight: "0px !important",

    },
    tabItem: {
        textTransform: "capitalize",
        fontFamily: FontFamilySwitch().medium,

        fontSize: "0.75rem",
        color: "#091B29",
        padding: "0px",
        minHeight: "auto",
        minWidth: "auto",
        marginRight: "10px",
        borderRadius: "16px",
        backgroundColor: "#fff",
    },
    tabItemSelect: {
        textTransform: "capitalize",
        fontFamily: FontFamilySwitch().semiBold,
        fontSize: "0.875rem",
        color: "#4E5A6B",
        borderBottom: "3px solid #091B29",
        marginRight: "8px",
        minHeight: "auto",
        padding: '0px !important',
        "& .MuiButtonBase-root-MuiTab-root": {
            minHeight: "0px",

        },
        minWidth: "auto",
    },
    title: {
        padding: "8px 12px",
        borderRadius: "4px",
        color: '#000000'
    },
    titleselect: {
        padding: "8px",
        color: "#091B29",
        fontFamily: FontFamilySwitch().semiBold,
        fontSize: "0.875rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    more: {
        border: "1px solid #E4E8EE",
        borderRadius: "4px",
        backgroundColor: "#FFFFFFF",
    },
    label: {
        marginLeft: "5px",
        fontFamily: FontFamilySwitch().medium,

        fontSize: "0.75rem"
    }
}