import { borderRadius, remCalc } from "../../utils";
import { FontFamilySwitch } from "../../utils/font";

export const BookingCardStyle = {
  topbox: {
    display: "flex",
    // height: "112px",
    backgroundColor: "primary.contrastText",
    padding: "8px",
    border: "1px solid #E4E8EE",
    borderRadius: borderRadius,
    alignItems: "center",
    width: "100%"
  },
  image: {
    height: "64px",
    width: "64px",
  },
  boximage: {
    display: "flex",
    alignItems: "center",
    padding: "8px 8px 8px 12px",
  },
  text: {
    marginLeft: "5px",
  },
  maintext: {
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: remCalc(18),
    color: "text.primary",
    lineHeight: 1.7,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "86%"
  },
  subtext: {
    fontSize: remCalc(12),
    fontFamily: FontFamilySwitch().medium,
    color: "#4E5A6B",
    lineHeight: 1.4,
    // '@media screen and (min-width: 320px)': {
    //   width: "90%"
    // },
    // '@media screen and (min-width: 375px)': {
    //   width: "100%"
    // },
    // '@media screen and (min-width: 425px)': {
    //   width: "100%"
    // },
    width: {xl: "100%", lg: "100%", md: "75%", sm: "100%", xs: "100%"}
  },
};
