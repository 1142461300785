import { FontFamilySwitch } from "../../utils/font";

export const ResidentialBreadcrumbStyle = {
    text: {

        fontSize: "0.75rem !important",
        fontFamily: FontFamilySwitch().regular,
        color: "#4E5A6B"

    },
    endtext: {
        fontSize: "0.75rem !important",
        fontFamily: FontFamilySwitch().medium,
        color: "#4E5A6B",

    },
    breadcrum: {
        "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall": {
            color: "#CED3DD"
        },
        "& .MuiBreadcrumbs-separator": {
            marginLeft: "0px",
            marginRight: "0px"

        },
    }



}
