import { borderRadius, borderRadiusAlignments, remCalc } from "../../utils";
import { FontFamilySwitch } from "../../utils/font";

export const MapBoxStyle = {
    buttonGroup: {
        borderRadius: 50,
        height: 27,
        background: "#fff",
        marginLeft: 12,
        position: "absolute",
        top: 17,
        right: 50,
        fontSize: "0.75rem"
    },
    button: {
        padding: '9px', borderRadius: 50, fontSize: "0.75rem",
        color: 'text.secondary',
        fontFamily: FontFamilySwitch().semiBold,
        textAlign: "center",
        textTransform: 'capitalize'
    },
    buttonSelected: {
        fontSize: "0.9rem",
        color: 'text.secondary',
        fontFamily: FontFamilySwitch().semiBold,
        textAlign: "center",
        backgroundColor: 'primary.main'
    },
    main: {
        height: 400, margin: '10px', position: "relative"
    },
    mainMap: {
        borderRadius: '10px'
    },
    radiusMain: {
        height: '100%',
        width: '100%',
        position:"relative"
    },

    map: {
        width: "100%", height: "100%"
    },
    radiusMap: {
        // width: '100%', borderRadius: borderRadiusAlignments?.borderTwelve,
        // flex: {
        //     xs: "calc(100vh - 310px)",
        //     sm: "calc(100vh - 310px)",
        //     md: "calc(100vh - 310px)",
        //     lg: "calc(100vh - 170px)",
        //     xl: "calc(100vh - 170px)",

        // }
        width: '100%', borderRadius: borderRadiusAlignments?.borderTwelve,
        height: "calc(100vh - 170px)"
        // height: "calc(100vh - 170px)",
    },
    radiusMapheight:{
        width: '100%', borderRadius: borderRadiusAlignments?.borderTwelve,
        height: "calc(100vh - 330px)"

    },
    pin: {
        fill: '#1F74EA'
    },
    flex: {
        display: 'flex',
        gap: '5px',
        fontSize: '1.1rem'
    },
    flex3: {
        display: 'flex',
        gap: 3
    },
    mainFlex: {
        display: 'flex', p: 0.75, cursor: "pointer",

    },
    mainFlexNew: {
        cursor: "pointer",
        width: "335px"
    },
    content: {
        display: 'flex', flexDirection: 'column', gap: 1
    },
    g1: {
        display: 'flex',
        gap: 1
    },
    blueChip: {
        backgroundColor: '#1F74EA', color: '#fff'
    },
    blueChipNew: {
        color: '#6DAFB3',
        fontSize: remCalc(10),
        fontFamily: FontFamilySwitch().medium,
        borderRadius: borderRadius,
        padding: "3px 4px",
        backgroundColor: "#EDF7F8",
        border: "1px solid #6DAFB3",
    },
    community: {
        color: '#896DB3',
        fontSize: remCalc(10),
        fontFamily: FontFamilySwitch().medium,
        borderRadius: borderRadius,
        padding: "3px 4px",
        backgroundColor: "#F6F1FE",
        border: "1px solid #896DB3",
        marginInlineStart: "4px"
    },
    px: {
        fontSize: "0.75rem",
        color: 'text.secondary'
    },
    pxNew: {
        fontSize: "0.75rem",
        color: 'text.secondary',
        fontFamily: FontFamilySwitch().semiBold,
        height: "30px"
    },
    blueIcon: {
        color: "#1F74EA !important", mt: 1,
        marginLeft: "5px !important",
        marginRight: "9px !important",
        "& .MuiChip-root .MuiChip-icon": {
            marginLeft: "5px !important",
            marginRight: "9px !important"
        }
    },
    bold: {
        fontSize: "0.875rem",
        ml: 0.75,
        fontFamily: FontFamilySwitch().medium,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
    },
    boldHeader: {
        fontSize: remCalc(16),
        fontFamily: FontFamilySwitch().medium,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        // ml: 0.75,
    },
    secondary: {
        color: 'text.secondary'
    },
    logo: {
        width: "80px",
        objectFit: "cover",
        borderRadius: "4px"
    },
    logoNew: {
        width: "90px",
        objectFit: "cover",
        borderRadius: "4px",
        height: "97px"
    },
    cluster: {
        color: "#fff",
        background: "#1978c8",
        borderRadius: "50%",
        padding: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    text: {
        color: "#1F74EA",
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().semiBold
    },
    box: {
        display: "flex",
        // justifyContent: "center",
        alignItems: "center"
    },
    startFrom: {
        color: "#091B29",
        fontFamily: FontFamilySwitch().regular,
        fontSize: "0.75rem",
        marginBottom: "4px",
        marginTop: "8px",
    },
    startAmnt: {
        color: "#091B29",
        fontFamily: FontFamilySwitch().medium,
        fontSize: "0.875rem",
    },
    startMonth: {
        color: "#4E5A6B",
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().regular
    },
    startTaxes: {
        color: "#4E5A6B",
        fontFamily: FontFamilySwitch().regular,
        fontSize: "0.75rem",
        marginTop: "2px"
    },
    locationText: {
        color: "#98A0AC",
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().medium,
    },
    titleDiv: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    arrow: {
        zIndex: 999,
        position: "absolute",
        top: "20px",
        left: {
            lg: "20px",
            md: "20px",
            sm: "20px",
            xs: "6px",
        },
        cursor: "pointer",
        // boxShadow:"0px 0px 0px #00000082"
        // boxShadow: "#0000000F 0px 0px 32px",
    },
    vacantUnits: {
        backgroundColor: "#78B1FE",
        borderRadius: borderRadius,
        padding: "3px 4px"
    },
    vacantUnitsText: {
        color: "#fff",
        fontSize: remCalc(10),
        fontFamily: FontFamilySwitch().medium,
        marginInlineStart: "8px",
        backgroundColor: "#78B1FE",
        borderRadius: borderRadius,
        padding: "4px 0px",
        textAlign: "center",
        width: "45%"
    },
    facilityCardHead: {
        fontSize: "1rem",
        fontFamily: FontFamilySwitch().medium,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        color: "text.primary",
        marginBottom: "6px"
    },
    facilityCardSubHead: {
        color: "text.tertiary",
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().regular,
        marginBottom: "12px"
    },
    facilityCardChip: {
        color: "#fff",
        backgroundColor: "text.light1",
        fontSize: "0.625rem",
        fontFamily: FontFamilySwitch().semiBold,
        padding: "3px 4px",
        borderRadius: borderRadius,
        width: "120px",
        textAlign: "center"
    },
    facilityCardRightBox: {
        width: "100%",
        marginInlineStart: "8px"
    },
    facilityCardImages: {
        height: "97px",
        width: "90px",
        borderRadius: borderRadius
    }
}