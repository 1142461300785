import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./index.css";

// import required modules
import { borderRadius } from "../../utils";

export const CarouselSliders = ({
  data = [],
  logo = "",
  onClick = () => false,
  img_style,
}) => {
  const logoObject = [
    {
      url: logo,
      asset_type: 1,
    },
  ];

  const overallImages = logo?.length > 0 ? [...logoObject, ...data] : data;

  const getVideoId = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url?.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  };
  return (
    <Swiper
      cssMode={true}
      navigation={true}
      pagination={true}
      mousewheel={true}
      keyboard={true}
      modules={[Navigation, Pagination, Mousewheel, Keyboard]}
      className="mySwiper"
    >
      {overallImages
        ?.filter((item) => item?.asset_type !== 4)
        ?.map((val, index) => (
          <SwiperSlide onClick={() => onClick(val?.url)}>
            <>
              {val?.asset_type === 3 ? (
                <iframe
                  title={"myframe"}
                  style={
                    img_style ?? {
                      boxShadow: "0px 3px 6px #00000029",
                      borderRadius: borderRadius,
                      width: "100%",
                      opacity: 1,
                      objectFit: "fill",
                      height: "100%",
                    }
                  }
                  src={"https://www.youtube.com/embed/" + getVideoId(val?.url)}
                ></iframe>
              ) : (
                <img src={val.url} alt={index} style={img_style} />
              )}
            </>
          </SwiperSlide>
        ))}
    </Swiper>
  );
};
