import { FontFamilySwitch } from "../../../utils/font";

export const ForgetPasswordStyle = {
    mainBox: {
        height: "370px",
        padding: "18px"
    },
    mailtext: {
        color: "text.primary",
        fontFamily: FontFamilySwitch().medium,

        fontSize: "1.125rem",
    },
    subtext: {
        color: "#4E5A6B",
        fontFamily: FontFamilySwitch().regular,
        fontSize: "0.875rem",
        ml: "60px",
        mr: "60px"
    },
    Iconbox: {
        alignItems: "center",
        justifyContent: "center",
        display: "flex"
    },
    button: {
        mt: "50px",
        ml: "12px",
        backgroundColor: "text.default",
        height: "44px",
        borderRadius: "26px",
        width: "93%",
        color: "#1F74EA",
        fontFamily: FontFamilySwitch().medium,
        fontSize: "0.875rem",
        border: "1px solid #1F74EA",
        "&:hover": {
            border: "1px solid #1F74EA",
            color: "#1F74EA",
            backgroundColor: "text.default",
        },
    },
    content: {
        mt: "35px"
    },
    inputBarSx: {
        padding: "0px 20px 0px 20px",
        mt: "24px",
        "& .MuiTypography-root": {
            color: "text.tertiary",
            fontFamily: FontFamilySwitch().semiBold,
            fontSize: "0.75rem",
        }

    },
    email: {
        "& .MuiOutlinedInput-root": {
            marginTop: "9px",
            borderRadius: "4px",
            height: "40px",
            borderColor: "border.default",


            "&.Mui-focused fieldset": {
                border: "1px solid #CED3DD"
            },
            '&:hover fieldset': {
                border: "1px solid #CED3DD"
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderRadius: "4px",

            },
        },
        "& .MuiTypography-root": {
            color: "text.tertiary",
            fontFamily: FontFamilySwitch().semiBold,
            fontSize: "0.75rem",
        },

    },
    mailIcon: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }

}