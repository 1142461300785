import React from "react";
import { LinearProgress } from "@mui/material";
import { LocalStorageKeys, semverGreaterThan, VersionFetchURL } from "./utils";
import { AuthContext } from "./contexts";
import { AppRoutes } from "./router/routes";
import { VersionAlert } from "./components/versionAlert";
// import { useRefreshQuery } from "./redux/services/auth";

export const AppAuth = (props) => {
  // const { isLoading } = useRefreshQuery();
  // const history = useNavigate();
  const myArray = window.location.pathname.split("/"); // split the string by "/"
  const pathName = myArray[1];
  const isLoading = false;
  const updateAuth = (key, value) => {
    if (!RoutesArray.includes(pathName)) {
      if (pathName?.length > 0) {
        setAuth((prevAuth) => ({
          ...prevAuth,
          clientRoutes: {
            home: `/${pathName}${"/home"}`,
            login: `/${pathName}${"/login"}`,
            listing: `/${pathName}`,
            country: `/${pathName}${"/country"}`,
            listingSearch: `/${pathName}${"/listingsearch"}`,
            commercialSearch: `/${pathName}${"/commercialSearch"}`,
            staysSearch: `/${pathName}${"/staysearch"}`,
            staysScreen: `/${pathName}${"/staysScreen"}`,
            staysView: `/${pathName}${"/staysview"}`,
            residentView: `/${pathName}${"/residentview"}`,
            commercialView: `/${pathName}${"/commercialview"}`,
            facilities: `/${pathName}${"/facilities"}`,
            commercial: `/${pathName}${"/commercial"}`,
            facilityBooking: `/${pathName}${"/facilitybooking"}`,
            confirmBooking: `/${pathName}${"/confirmbooking"}`,
            searchResults: `/${pathName}${"/searchResults"}`,
            viewDetails: `/${pathName}${"/viewDetails"}`,
            bookingFlow: `/${pathName}${"/bookingFlow"}`,
            bookingCompleted: `/${pathName}${"/bookingCompleted"}`,
            shortlist: `/${pathName}${"/shortlist"}`,
            facilityBookingDetails: `/${pathName}${"/facilityBookingDetails"}`,
            checkAvailability: `/${pathName}${"/checkAvailability"}`,
            myShortlist: `/${pathName}${"/myShortlist"}`,
          },
          [key]: value,
        }));
      } else {
        setAuth((prevAuth) => ({
          ...prevAuth,
          [key]: value,
        }));
      }
    } else {
      setAuth((prevAuth) => ({
        ...prevAuth,
        [key]: value,
      }));
    }
  };
  const [auth, setAuth] = React.useState({
    clientRoutes: AppRoutes ?? {},
    updateAuth: updateAuth,
    homePage: false,
    currency: {},
    metric: {},
  });

  const [state, setState] = React.useState({
    is_version_alert_open: false,
    new_version: "",
  });

  const RoutesArray = [
    "home",
    "login",
    "country",
    "listingsearch",
    "commercialSearch",
    "staysearch",
    "staysScreen",
    "staysview",
    "residentview",
    "commercialview",
    "facilities",
    "commercial",
    "facilitybooking",
    "confirmbooking",
    "searchResults",
    "viewDetails",
    "bookingFlow",
    "bookingCompleted",
    "imageList",
    "shortlist",
    "shortListed",
    "checkAvailability",
    "myShortlist",
  ];

  const checkForLatestBuild = () => {
    if (!RoutesArray.includes(pathName)) {
      if (pathName?.length > 0) {
        localStorage.setItem(LocalStorageKeys.client, pathName);
        setAuth({
          ...auth,
          clientRoutes: {
            home: `/${pathName}${"/home"}`,
            login: `/${pathName}${"/login"}`,
            listing: `/${pathName}`,
            country: `/${pathName}${"/country"}`,
            listingSearch: `/${pathName}${"/listingsearch"}`,
            commercialSearch: `/${pathName}${"/commercialSearch"}`,
            staysSearch: `/${pathName}${"/staysearch"}`,
            staysScreen: `/${pathName}${"/staysScreen"}`,
            staysView: `/${pathName}${"/staysview"}`,
            residentView: `/${pathName}${"/residentview"}`,
            commercialView: `/${pathName}${"/commercialview"}`,
            facilities: `/${pathName}${"/facilities"}`,
            commercial: `/${pathName}${"/commercial"}`,
            facilityBooking: `/${pathName}${"/facilitybooking"}`,
            confirmBooking: `/${pathName}${"/confirmbooking"}`,
            searchResults: `/${pathName}${"/searchResults"}`,
            viewDetails: `/${pathName}${"/viewDetails"}`,
            bookingFlow: `/${pathName}${"/bookingFlow"}`,
            bookingCompleted: `/${pathName}${"/bookingCompleted"}`,
            imageList: `/${pathName}${"/imageList"}`,
            shortlist: `/${pathName}${"/shortlist"}`,
            facilityBookingDetails: `/${pathName}${"/facilityBookingDetails"}`,
            checkAvailability: `/${pathName}${"/checkAvailability"}`,
            myShortlist: `/${pathName}${"/myShortlist"}`,
          },
        });
      } else {
        localStorage?.removeItem(LocalStorageKeys.client);
      }
    } else {
      localStorage?.removeItem(LocalStorageKeys.client);
    }
    fetch(VersionFetchURL)
      .then((res) => res.json())
      .then(
        (_) => {
          let temp_version = localStorage.getItem(LocalStorageKeys.version);
          if (temp_version) {
            const isVersion = semverGreaterThan(_.version, temp_version);
            if (isVersion) {
              handleVersionAlert(_.version);
            }
          } else {
            localStorage.setItem(LocalStorageKeys.version, _.version);
          }
        },
        (error) => {
          console.log("Error at Fetching Latest Version: ", error);
        }
      );
  };
  const handleVersionAlert = (version) => {
    setState({
      is_version_alert_open: !state?.is_version_alert_open,
      new_version: version,
    });
    localStorage.setItem(LocalStorageKeys.version, version);
  };

  React.useLayoutEffect(
    () => {
      checkForLatestBuild();
    },
    // eslint-disable-next-line
    []
  );
  // console.log(auth, "auth");
  return (
    <>
      <AuthContext.Provider value={auth}>
        {isLoading && <LinearProgress />}
        <VersionAlert handleVersionAlert={handleVersionAlert} state={state} />
        {props.children}
      </AuthContext.Provider>
    </>
  );
};
