import React, { useState, useEffect } from "react";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { AppRoutes } from "./routes";
import { AlertProps, LocalStorageKeys } from "../utils";
import { NewLoader } from "../components";
import { AlertContext } from "../contexts";

const PrivateRoute = ({ path, children, ...rest }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const alert = React.useContext(AlertContext);

  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const checkTokenExpiry = () => {
    const expirationTime = localStorage.getItem("expirationTime");

    if (!expirationTime) {
      return false;
    }

    const currentTime = new Date().getTime();
    const isTokenValid = currentTime < Number(expirationTime);

    if (!isTokenValid) {
      localStorage.removeItem("user_Id");
      localStorage.removeItem("username");
      localStorage.removeItem("auth_token");
      navigate(AppRoutes.listing);
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Session expired, please log in again.",
      });
      return false;
    }

    return true;
  };

  const isAuthenticated = () => {
    const authToken = localStorage.getItem(LocalStorageKeys.authToken);
    return authToken && checkTokenExpiry();
  };

  useEffect(() => {
    setLoading(true);
    setAuthenticated(isAuthenticated());
    setLoading(false);
  }, [location]);

  if (loading)
    return (
      <div>
        <NewLoader />
      </div>
    );

  return (
    <>
      {authenticated ? (
        children
      ) : (
        <Navigate to={AppRoutes.listing} state={{ from: location }} />
      )}
    </>
  );
};

export default PrivateRoute;
