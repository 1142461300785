// Old Font Family Commented As per Request

// export const Regular = "'NotoSans-Regular'";
// export const Bold = "'NotoSans-Bold'";
// export const SemiBold = "'NotoSans-SemiBold'";
// export const ExtraBold = "'NotoSans-ExtraBold'";
// export const Italic = "'NotoSans-Italic'"

export const Regular = {
  fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'NotoSans-Regular'",
  fontWeight: 400,
};
export const Medium = {
  fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'NotoSans-Medium'",
  fontWeight: 500,
};
export const SemiBold = {
  fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'NotoSans-SemiBold'",
  fontWeight: 500,
};
export const ExtraBold = {
  fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'NotoSans-ExtraBold'",
  fontWeight: 700,
};
export const Italic = {
  fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'NotoSans-Italic'",
  fontStyle: "italic",
};
export const Bold = {
  fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'NotoSans-Bold'",
  fontWeight: 600,
};

export const FontFamilySwitch = () => {
  const language = localStorage.getItem("i18nextLng");
    const fontStyles = {
        regular: `${Regular.fontFamily}; font-weight: ${Regular.fontWeight}`,
        semiBold: `${SemiBold.fontFamily}; font-weight: ${SemiBold.fontWeight}`,
        extraBold: `${ExtraBold.fontFamily}; font-weight: ${ExtraBold.fontWeight}`,
        italic: `${Italic.fontFamily}; font-style: ${Italic.fontStyle}`,
        bold: `${Bold.fontFamily}; font-weight: ${Bold.fontWeight}`,
        medium: `${Medium.fontFamily}; font-weight: ${Medium.fontWeight}`,
    };
    switch (language) {
    case "en":
      return fontStyles;
    default:
      return fontStyles;
  }
};